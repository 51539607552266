.pt-50, .py-50 {
    padding-top: 47px !important;
}

.p-15 {
    padding: 15px;
}

.items-search-box-icon {
    border: none;
}

.slider-wrapper__img-wrapper {
    margin-left: 1rem;
    box-shadow: none !important;
    background: #e7e7e700;
    border-radius: 50%;
    padding: 3px;
}

.customizable-item-text {
    color: #ffffff00 !important;
}

/* .category-list-item a > img {*/
/*    border: 0;*/
/*    border-radius: 14px;*/
/*}*/

#btn-customization-done {
    border-radius: 13px;
    width: 100%;
    margin-left: 20px;
    height: 58px !important;
    /*text-transform: initial;*/
    font-size: 1.2rem;
}

#customizationBottomContainer {
    display: flex;
    border-top: #929292 1px solid;
    position: fixed;
    bottom: 0;
    width: 100%;
    padding: 15px 20px 16px 20px;
    background: white;
    /*z-index: 1003;*/
    gap: 10px;
}

#customizationPlusMinusContainer {
    width: auto;
    height: 35px;
    flex-flow: row nowrap;
    display: flex;
    justify-content: center;
    align-self: center;
}

#customizationQtyDisplay {
    margin: 5px 10px;
}

.search-shadow-light {
    box-shadow: 0 0 5px #8d8d8d73;
    border-radius: 10px;
}

.btn-sm, .btn-group-sm > .btn {
    margin-right: 3px;
    width: 15px;
}

.my-account-menu-item-block .text-center {
    font-size: 1rem;
    font-weight: 700;
}

.styles_closeButton__20ID4 {
    display: none !important;
}

.modalCloseButton {
    position: sticky;
    float: right;
    top: 12px;
    right: 20px;
    border: none;
    padding: 0;
    background-color: transparent;
    z-index: 1002;
    display: flex;
}

.btn-quantity {
    height: 2.1rem !important;
    background-color: transparent;
    border-radius: 30px !important;
    min-width: 35px;
}

.cart-item-price {
    margin-top: -9px;
    font-weight: 700;
    margin-right: 0;
    margin-left: 5px;
    width: 85px;
    text-align: right;
}

.item-badge-collection {
    margin-bottom: 2px;
    margin-top: 2px;
    display: flex;
    gap: 3px;
}

.heart {
    position: absolute;
    width: 100px;
    height: 100px;
    right: -19px;
    top: 1.2rem;
    background: url(/assets/img/various/heart.png) no-repeat;
    cursor: pointer;
    transition: background-position 1s steps(28);
    transform: scale(0.75);
}


.react-responsive-modal-modal > div:nth-child(1) {
    /*max-width: inherit;*/
    margin: 2rem 2rem 0;
    flex-grow: 1;
}

div.react-responsive-modal-modal {
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 0;
    flex-grow: 1;
    overscroll-behavior: contain;
}

.magic-radio + label:before, .magic-checkbox + label:before {
    right: -20.6%;
}

.quantity-badge-list {
    position: initial;
    margin-left: 3px;
    box-shadow: 0 0 4px #00000063;
    margin-top: 3px;
    text-shadow: 0 0 5px #2b2b2b80;
    height: 20px;
    line-height: 19px;
    width: auto;
    padding-right: 0;
    min-width: 20px;
    letter-spacing: -0.5px;
    font-size: 1rem;
    font-weight: bold;
    border: none !important;
    color: #fff;
    display: block;
    text-align: center;
    z-index: 1;
}

.si-check:before {
    content: "\e080";
    color: white;
    line-height: 20px !important;
    font-size: 14px;
}

.modalCloseSvg {
    width: 34px;
    padding: 7px;
    height: 34px;
    box-shadow: -1px 6px 10px 18px #ffffff;
    background: #f5f5f5;
    margin-right: -6px;
    border-radius: 30px;
}

.react-responsive-modal-root:after {
    transform: translateY(-100%);
    transition: .4s ease-in-out;
}

div.react-responsive-modal-root {
    display: flex;
    flex-direction: row;
    flex-basis: max-content;
    justify-content: center;
    z-index: 999999;
}

div.react-responsive-modal-container {
    max-width: 800px;
    margin: 18px 0 18px;
    border-radius: 15px;
    display: flex !important;
    overflow-y: hidden;
    flex-direction: column;
    flex-basis: 800px;
    box-shadow: 0 0 200px 150px #3d3d3d;
    background-color: #fff !important;
    height: calc(100% - 38px);
    outline: 0;
    overflow-x: hidden;
    text-align: center;
}

.item-badge {
    color: #fafafa;
    background: linear-gradient(
            137deg, rgb(241 145 84) 0%, rgb(250 66 87) 100%);
    font-size: 8px;
    text-transform: uppercase !important;
    padding: 0.25rem 1rem;
    font-weight: 700;
    border-radius: 9px;
}

.footer-fixed {
    position: fixed;
    height: 76px;
    z-index: 10;
}

.btn-group > .btn:not(:last-child):not(.dropdown-toggle), .btn-group > .btn-group:not(:last-child) > .btn {
    width: 69%;
    border: none;
    margin-left: 6px !important;
}

.delivery-address-timeline .btn-get-direction {
    background-color: #8360c3;
    border-radius: 4px;
    color: #fff;
    border: 0;
    height: 2.4rem;
    line-height: 5px;
}

.slider-bg-color {
    background: linear-gradient(
            137deg, rgb(120 255 202) 0%, rgb(15 218 91) 100%);
}

.btn-get-direction {
    background: linear-gradient(
            137deg, rgb(120 196 255) 0%, rgb(100 15 218) 100%);
    box-shadow: 1px 0 12px 0 #665af8c2;
    color: #fff;
    border-radius: 6px;
    border-style: none;
    font-weight: 700;
}

.ml-15, .mx-15 {
    padding-top: 10px !important;
    margin-left: 15px !important;
}

.mb-5, .my-5 {
    margin-top: -3px;
}

.footer-links {
    color: #8e8e8e;
    font-size: 11px;
    font-weight: 600;
}

.p-3 {
    margin-top: 56px;
    padding: 1rem !important;
}

.btn-refresh-status {
    width: 100%;
    position: fixed;
    bottom: 0;
    border: 0;
    border-radius: 0;
    height: 4rem;
    font-size: 1.2rem;
}

.input-group-append {
    margin-left: -1px;
}

.btn-preference-group {
    border: 0;
}


.input-group > .form-control:not(:last-child), .input-group > .custom-select:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border: none !important;
    font-size: 14px;
    font-weight: 500;
}

.flex-item-image {
    /*height: 10vh;*/
    width: 100px;
    border-radius: 12px !important;
    object-fit: contain;
}

.list-item-name {
    font-weight: 600;
    color: black;
    font-size: 17px;
    line-height: 1.5;
}

.list-item-name:hover {
    color: #000000;
}

.account-menu {
    display: grid;
    overflow: scroll;
    grid-template-columns:auto auto auto;
    grid-row-gap: 13px;
}

.input-group > .form-control, .input-group > .custom-select, .input-group > .custom-file {
    font-weight: 700;
    border: 0 solid #e8e8e8;
}

.si-share:before {
    content: "\e05b";
    color: black;
    font-weight: 800;
}

.btn-add-remove-group, .add-item-button {
    width: 100px !important;
    max-width: 100px !important;
    min-width: 100px !important;
    height: 28px !important;
}

.add-item-button {
    background-color: rgb(25, 118, 210) !important;
    font-size: 1rem !important;
}

.item-count {
    border-left: 1px white solid !important;
    border-right: 1px white solid !important;
    font-size: 1.2rem !important;
}

.btn-add-remove {

    background-color: rgb(25, 118, 210);
    width: 33px !important;
    max-width: 33px !important;
    min-width: 33px !important;
    color: #fff !important;
    margin-left: 0 !important;
}

button.btn.btn-add-remove:disabled {
    opacity: 0;
}

.category-list-item {
    font-size: 11px;
    font-weight: 400;
    color: #686868d9;
    text-transform: capitalize;
    border-radius: 0 !important;
    border-bottom: 1px solid rgba(207, 207, 207, 0.52) !important;
    margin-bottom: 10px;
    padding-bottom: 10px;
}

.order-track-button {
    color: #2ae78f;
}

.pulse {

    background: #22e68b;
}

/*.order-item-quantity {*/
/*    border: 0;*/
/*    font-weight: 800;*/
/*    color: rgb(255 255 255 / 90%);*/
/*    padding: 1px 0 !important;*/
/*    font-size: 13px;*/
/*    margin-top: 1px;*/
/*    min-width: 32px;*/
/*    height: 23px;*/
/*    border-radius: 7px;*/
/*    text-align: center;*/
/*    background: #6641d8;*/
/*}*/

.my-account-menu-item i.si-home {
    background: linear-gradient(
            137deg, rgb(120 196 255) 0%, rgb(100 15 218) 100%);
}

.my-account-menu-item i.si-wallet {
    background: linear-gradient(
            137deg, rgb(120 255 140) 0%, rgb(14 177 64) 100%);
}

.my-account-menu-item i.si-tag {
    background: linear-gradient(
            137deg, rgb(197 197 197) 0%, rgb(66 66 66) 100%);
}

.my-account-menu-item i.si-heart {
    background: linear-gradient(
            137deg, rgb(255 120 142) 0%, rgb(185 11 131) 100%);
}

.my-account-menu-item i.si-bag {
    background: linear-gradient(
            137deg, #ec94ff 0%, rgb(90 15 218) 100%);
}

.my-account-menu-item i {
    font-size: 1.2rem;
    color: #ffffff;
    padding: 8px 9px 8px 9px;
    border-radius: 50%;
    text-align: center;
}

.account-menu .my-account-menu-item-block {
    border-radius: 8px;
    padding: 10px !important;
}

.tip-switch-field input:checked + label {
    background-color: rgb(25, 118, 210);
}

.btn-continue {
    width: 104%;
    border-radius: 0;
    height: 4rem;
    font-size: 1.1rem;
    font-weight: 700;
    display: block;
    color: #fff;
    text-transform: uppercase;
    text-align: center;
    line-height: 2.7rem;
    bottom: 0;
    position: fixed !important;
    margin: 0 auto 0 -2rem;
}

.Collapsible__trigger:after {
    margin-right: -20px;
    background: #e7e7e759;
    color: #5d5d5d;
    float: right;
    padding: 4px 10px;
    border-radius: 20px;
}

.btn-make-payment {
    width: 101%;
    margin: 0 auto;
    padding: 0.9375rem 0;
    line-height: 1.95rem;
    border: none !important;
    font-size: 1.1rem;
    font-weight: 700;
    display: block;
    text-transform: uppercase;
    border-radius: 0;
    height: 4rem;
    bottom: 0;
    position: fixed !important;
}

.bg-grey-light {
    margin-top: 0 !important;
    border-radius: 18px;
    box-shadow: 0 -7px 7px 0 #0000000f;
    background-color: rgba(250, 250, 250, 0.5) !important;
}

.mock-search-block > div {
    box-shadow: 0 0 10px #b8b8b82b;
    border: 1px solid #e4e4e49c;
}

.mock-search-block {
    background: #f8f8f8;
    box-shadow: none !important;
    margin-top: -1px;
    padding-top: 11px;
    margin-bottom: -1px;
}

/*.itemlist-search-block > div {*/
/*    box-shadow: 0 0 10px #b8b8b82b;*/
/*    border: 1px solid #e4e4e49c;*/
/*    background-color: #fff;*/
/*    height: 3.8rem;*/
/*    border-radius: 0.775rem;*/
/*    -webkit-box-align: center;*/
/*    -ms-flex-align: center;*/
/*    align-items: center;*/
/*    -webkit-box-shadow: 0px 4px 10px -10px #ccc;*/
/*}*/

/*.itemlist-search-block {*/
/*    background: #f8f8f8;*/
/*    box-shadow: none !important;*/
/*    padding-top: 11px;*/
/*}*/

/*.itemlist-search-block > input {*/
/*    border-radius: 0.775rem;*/
/*}*/


/*.itemlist-search-block span {*/
/*    font-size: 1.1rem;*/
/*    font-weight: 500;*/
/*    color: #ababab;*/
/*    letter-spacing: -0.1px;*/
/*}*/

/*.itemlist-search-block i {*/
/*    color: #ababab;*/
/*    font-weight: 700;*/
/*}*/

.item-list-subtitle {
    font-style: italic;
    font-size: 1.3rem;
    opacity: 0.8;
}

.Collapsible__trigger {
    width: 95%;
    text-align: left;
    margin-left: 2px;
    font-size: 3vh;
    border: none;
    font-weight: 700 !important;
}

.bg-light {
    background-color: #f8f8f8 !important;
    box-shadow: none;
}

.addon-category-name {
    margin-left: -34px;
    height: 36px;
    font-weight: 700;
    border-radius: 0 15px 15px 0;
    background: linear-gradient(
            137deg, rgb(120 196 255) 0%, rgb(100 15 218) 100%);
    color: #ffffff;
    box-shadow: 0 0 7px #00000045;
    font-size: 1rem;
    margin-bottom: 1rem !important;
    display: block;
    border-style: none;
    padding: 8px 33px;
    width: fit-content;
}

.coupon-success {
    margin-top: 7px;
    background-color: #49ce91;
    color: #fff;
    font-weight: 500;
    border-radius: 5px;
    border: 0;
}

.coupon-fail {
    margin-top: 7px;
    background-color: #f43650;
    color: #fff;
    font-weight: 500;
    border-radius: 5px;
    border: 0;
}

.coupon-not-loggedin-message {
    margin-top: 5px !important;
    border-radius: 0.3rem !important;
}

.magic-radio + label:after {
    background: #2db1ff;
}

.magic-checkbox:checked + label:before {
    border: #2db1ff;
    background: #2db1ff;
}

.restaurant-block {
    width: auto;
    display: flex;
    flex-direction: column;
}

.restaurant-image {
    width: 100%;
    height: 100%;
    border-radius: 0.5rem;
    margin-top: 0;
    margin-bottom: 0;
    /*max-width: 350px;*/
    /*max-height: 130px;*/
    align-self: center;
    object-fit: contain;
}

.restaurant-info {
    padding-bottom: 5px !important;
    padding-top: 0;
    margin-top: 0;
    padding-left: 0;
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.slider-wrapper__img {
    border-radius: 0.6rem;
}

.btn-group .btn-preference:last-child {
    border-radius: 0 25px 25px 0;
    margin-left: -1px;
    border: none;
}

.category-checkboxes-block {
    padding-bottom: 13px;
}

.border-radius-4px {
    border-radius: 4px;
}

.cart-item-btn {
    width: 2px;
    margin: 10px;
}

.menu-item-block-single {
    font-weight: 700;
}

.font-w600 {
    font-weight: 600 !important;
}

.restaurant-list-name {
    font-weight: 600;
    font-size: 20px;
}

.item-category-title {
    font-size: 1.8rem;
    font-weight: 700
}

.store-logo {
    height: 43px;
    margin-top: -2px;
    margin-left: -121px;
    max-width: 125px;
    animation: slide 1.4s forwards;
    object-fit: contain;
    background: linear-gradient(to right, rgb(119 91 91 / 0%) 0%, rgb(255 255 255 / 0%) 50%, rgb(128 186 232 / 0%) 99%, rgb(125 185 232 / 0%) 100%);
}

.restaurant-info > h2 {
    font-size: 1.4rem;
    margin-top: -7px;
}



.secondary-slider-wrapper {
    background-color: #f8f8f8;
    padding-top: 17px;
    /*box-shadow: 0 80px 20px #f8f8f8;*/
}

.flex-item-name .item-price {
    line-height: 2rem;
    margin-right: 5px;
    font-weight: 600;
    color: black;
    font-size: 1.1rem;
}

.btn-group > .btn:not(:last-child):not(.dropdown-toggle), .btn-group > .btn-group:not(:last-child) > .btn {
    width: 0;
    margin-left: 0 !important;
}

.menu-button-block-main {
    left: 85%;
}

.btn-menu-list {
    margin-bottom: 5px;
    min-width: 93px;
    background-color: #1b1b1b !important;
}

.category-slider-name {
    position: absolute;
    transform: translate(-50%, -50%);
    color: white;
    background: rgba(0, 0, 0, 0.8);
    border-radius: 9px;
    font-weight: 500;
    font-size: 0.9rem;
    left: 50%;
    top: 100%;
    text-align: center;
    padding: 0 7px 1px 7px;
}

.user-preferred {
    background-image: linear-gradient(
            137deg, rgb(120 196 255) 0%, rgb(100 15 218) 100%) !important;
    box-shadow: 0 0 7px #603dff73 !important;
    color: #fff;
    border-style: none;
    font-weight: 700;
    border-radius: 25px !important;
    transition: 0.25s ease-in-out;
    transition-property: border-radius;
}

.fa-star:before {
    color: #ffb500;
    font-size: 11px;
    content: "\f005";
}

a.block {
    margin-left: 10px;
    margin-right: 10px;
    padding-top: 1px !important;
    /*height: 110px;*/
    margin-top: 12px !important;
    border-radius: 0;
    /*box-shadow: 0 0 7px #00000021;*/
    color: #575757;
    font-weight: 400;
    transition: all 0.12s ease-out;
    padding-left: 5px;
    padding-right: 5px;
}

.active-footer-tab {
    color: rgb(25, 118, 210);
    font-weight: 600;
}

.fa-2x {
    color: #b7b7b7;
    font-size: 1.9em;
    font-weight: 1000;
}

.category-checkboxes-block input:checked + span {
    padding: 5px 13px;
    color: #fff;
    background: linear-gradient(
            137deg, rgb(120 196 255) 0%, rgb(100 15 218) 100%);
    border-style: none;
    font-weight: 500;
}

.filter-count-block {
    border: 2px solid rgb(48 48 48);
    margin-bottom: 7px;
    color: rgb(35 35 35);
}

.btn-inc {
    font-size: 1.2rem;
    line-height: 14px;
    color: #ffffff;
    font-weight: 700;
}

.btn-dec {
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 14px;
    color: #FFFFFF;
}

.float-cart {
    font-size: 15px;
    border-bottom: none;
    box-shadow: 0 0 20px 11px #454e6600;
    position: fixed;
    width: 100%;
    text-shadow: 0 0 18px #251f2f4d;
    height: 4.3rem;
    padding-left: 20px !important;
    padding-right: 20px !important;
    padding-top: 13px;
    font-weight: 600;
    z-index: 9;
    margin-bottom: 2px;
}

.bg-white {
    border-radius: 5px !important;
}

.current-location-btn-overmap {
    display: none !important;
}

.cart-delivery-distance {
    display: none !important;
}

.gps-selection-block {
    display: none !important;
}

.m-0 {
    font-weight: 400;
    font-size: 12px;
}

.explore-bg {
    display: none;
}

.si.si-bell.fa-2x {
    background-image: url("/assets/img/alerts.png") !important;
    background-repeat: no-repeat;
    background-size: 100%;
    color: transparent !important;
}


.si.si-magnifier.fa-2x {
    background-image: url("/assets/img/search.png") !important;
    background-repeat: no-repeat;
    background-size: 100%;
    color: transparent !important;
}

.si.si-bag.fa-2x {
    background-image: url("/assets/img/cart.png") !important;
    background-repeat: no-repeat;
    background-size: 100%;
    color: transparent !important;
}

.si.si-user.fa-2x {
    background-image: url("/assets/img/user.png") !important;
    background-repeat: no-repeat;
    background-size: 100%;
    color: transparent !important;
}

.deliver-to-text {
    font-weight: bold;
}

.user-address {
    font-size: 15px;
    text-overflow: initial;
    overflow: visible;
    white-space: normal;
}

a.single-store-homepage {
    /*padding: 1px 10px 0px 10px !important;*/
    padding: 0;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    height: auto !important;
}

.ribbon > .ribbon-box {
    top: -130px;
    right: 0;
    z-index: 1;
}

.restaurant-info > hr {
    margin-top: 5px !important;
    margin-bottom: 4px !important;
    color: #0000001c;
    width: 100%;

}

div.restaurant-meta {
    margin-top: 0 !important;
}

.single-store-homepage > div.block-content {
    padding: 0 0 10px 0;
    display: flex;
    flex-direction: column;
}

div.restaurant-block {
    padding: 0 10px;
}

.store-distance-block, .store-rating-block {
    font-size: 1rem;
}

.restaurant-block .block-content > div > p,
.restaurant-block .block-content > div > h3,
.restaurant-block .block-content > div > h2,
.restaurant-block .block-content > div > h1 {
    margin-bottom: 0 !important;
    margin-left: 0 !important;
}

.single-store-homepage {
    max-width: 400px !important;
    margin: auto !important;
}


.recommended-item-content {
    height: auto;
    min-height: 0;
}

div.quantity-badge-recommended {
    font-size: 1.1rem;
    font-weight: bold;
    position: static;
}

/*your cart/delivery instructions headings at checkout*/
.item-text {
    font-weight: 500 !important;
    font-size: 1.6rem;
    line-height: 1.1rem;
}

/*driver tip/order detail headings at checkout*/
.tips-block strong, .bill-details .bill-detail-text {
    font-weight: 500 !important;
    font-size: 1.6rem;
    line-height: 1.1rem;
}

/*item list menu category headings*/
.Collapsible__trigger {
    font-size: 2.1rem;
}

.restaurant-block > a > div.block-content {
    padding: 0;
}

:root {
    --form-control-color: #2db1ff;
    --form-control-disabled: #da3838;
}

.foodrunners-input {
    /* Add if not using autoprefixer */
    -webkit-appearance: none;
    /* Remove most all native input styles */
    appearance: none;
    /* Not removed via appearance */
    margin: 0;

    font: inherit;
    color: currentColor;
    width: 2em;
    min-width: 2em;
    height: 2em;
    border: 0.15em solid currentColor;
    transform: translateY(-0.075em);
    display: grid;
    place-content: center;
}

.foodrunners-input[type='radio'] {
    border-radius: 50%;
}

.foodrunners-input[type='checkbox'] {
    border-radius: 0.15em;
}
.foodrunners-input:before {
    width: 1.2em;
    height: 1.2em;
    content: "";
    transform: scale(0);
    transition: 120ms transform ease-in-out;
    box-shadow: inset 1em 1em var(--form-control-color);
}

.foodrunners-input[type='radio']:before {
    border-radius: 50%;
    /* Windows High Contrast Mode */
    background-color: CanvasText;
}

.foodrunners-input[type='checkbox']:before {
    clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
    transform-origin: bottom left;
    /* Windows High Contrast Mode */
    background-color: CanvasText;
}

.foodrunners-input[type='radio']:checked::before, .foodrunners-input[type="checkbox"]:checked::before {
    transform: scale(1);
}

.foodrunners-input:focus {
    outline: max(2px, 0.15em) solid currentColor;
    outline-offset: max(2px, 0.15em);
}

.foodrunners-input:disabled {
    /*--form-control-color: var(--form-control-disabled);*/
    /*color: var(--form-control-disabled);*/
    cursor: not-allowed;
}

.foodrunners-input-label {
    display: flex;
    gap: 0.5em;
}

.addon-label-price {
    flex-grow: 1;
}

#addressButton {
    overflow-x: hidden;
    flex-grow: 1;
    padding: 0 10px 0 0;
}

#addressButton span {
    flex-grow: 1;
    text-align: end;
}

#addressButton i {
    flex-basis: 10px;
}

textarea.order-comment {
    font-size: 1.1rem;
    box-shadow: none;
    padding: 6px 6px;
    border: solid;
    border-color: #e0e0e0;
    border-width: 1px;
    border-radius: 4px;

}

textarea.order-item-comment:focus {
    height: 3rem;
}

textarea.order-item-comment {
    height: 1.5rem;
}

#order-comment-container {
    padding-top: 0 !important;
}

.wave-container {
    z-index: -1;
}

.item-desc-short {
    font-size: 1.05rem;
}

.item-desc-short {
    font-weight: 500;
}

.item-badge {
    font-size: 0.7rem;
}

.item-price {
    font-size: 1.1rem;
    color: #3a3a3ad9;
    font-weight: 550;
}


.store-avgprice-block {
    display: none;
}

.customizable-item-text {
    display: none;
}

.google-places-autocomplete {
    /* margin: 0 15px !important; */
    width: auto !important;
}

.google-places-autocomplete input {
    color: #575757;
    height: 2rem;
    /*padding-left: 20px;*/
    font-size: 1rem;
    /* box-shadow: 0px 1px 6px 1px #9e9e9e3d; */
}

.customizationQtyDisplay {
    font-weight: 800;
}

.restaurant-closed-but-schedulable {
    position: absolute;
    top: 50%;
    left: 50%;
    max-width: 100%;
    width: max-content;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 1.1rem;
    font-weight: bold;
    background: white;
    border-radius: 5px;
    padding: 5px;
    box-shadow: 0 0 5px 5px white;
}

/*.google-places-autocomplete input:focus {*/
/*    -webkit-box-shadow: 0 0px 6px 2px #eee;*/
/*    box-shadow: 0 0px 6px 2px #eee;*/
/*    -webkit-transition: 0.2s all linear;*/
/*    -o-transition: 0.2s all linear;*/
/*    transition: 0.2s all linear;*/
/*    border-color: transparent;*/
/*}*/
#order-contactless-container {
    display: flex;
    flex-direction: column;
}

#order-contactless-text {
    display: flex;
    flex-direction: column;
    padding: 5px;
}

#order-contactless-text > h3 {
    margin-bottom: 5px;
}

.schedule-order-btn {
    background-color: rgb(25, 118, 210);
}

.item-list-left-column {
    display: flex;
    flex-direction: column;
    padding-right: 5px;
    flex-grow: 1;
}

.item-list-right-column {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100px;
}

.item-list-item-count {
    font-size: 1.3rem;
    font-weight: 600;
    color: black;
    /*width: 1rem;*/
    margin-left: 0.5rem;
    margin-right: 0.5rem;
}

.secondaryColor {
    background-color: #553D87 !important;
    color: white !important;


}

.badges {
    text-transform: uppercase !important;
    font-size: 0.7rem !important;
    font-weight: 700;
}

.item-category-chip > span {
    font-size: 1rem;
    margin-left: -4px;
}

.button-color {
    background-color: rgb(25, 118, 210) !important;
}

.contactless-checkbox {
    color: rgb(25, 118, 210) !important;
}

.available-rewards-button {
    min-width: max-content !important;
}

.available-rewards-button .MuiButton-startIcon {
    margin-right: 4px;
}

.running-order-container {
    width: 100%;
}

.btn-cart-unavailable {
    width: 132.05px !important;
}

.line-wrap-anywhere {
    white-space: break-spaces;
}

.custom-promo-img {
    height: 200px;
    object-fit: contain;
    max-width: 95vw;
}

.restaurant-block.last-child {
    margin-bottom: 15px;
}

.restaurant-list-footer {
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 15px;
    display: flex;
    border-top: 1px solid #e0e0e0;
}

.restaurant-list-footer .footer-column {
    margin-bottom: 20px;
}

.restaurant-list-footer .footer-social-buttons {
    padding: 8px 10px;
}

.restaurant-list-footer li {
    display: flex;
    justify-content: center;
}

.restaurant-list-footer span, .restaurant-list-footer a {
    font-size: 1.15rem;
}

.restaurant-list-footer .footer-header {
    font-size: 1.5rem;
    font-weight: 600;
    color: #0076D2;
    margin-bottom: 5px;
    border-bottom: 1px solid #e0e0e0;
}

#app-advertising-banner-container {
    display: flex;
    align-items: center;
    min-height: 36px;
    background-color: #0076D2;
    margin-bottom: 1px;
    padding: 3px;
    color: white;
    font-weight: 700;
    gap: 5px;
}

.google-play-button {
    width: 130px;
    image-rendering: high-quality;
}

.no-outline-focus:focus {
    outline: none !important;
}

.checkout-button {
    display: flex !important;
    background: white !important;
    align-items: center;
    padding: 0 !important;
    width: 100%;
    height: 3rem;
}

.checkout-section {
    display: flex !important;
    background: white !important;
    flex-direction: column;
    margin-bottom: 8px;
    padding: 5px 10px;
}

.checkout-section h3 {
    font-weight: 600;
    font-size: 1.2rem;
    margin: 5px 0 5px 0;
}

.checkout-error-section {
    /*border: 1px red solid;*/
    /*border-radius: 15px;*/
    box-shadow: 0 0 10px 0 rgba(255, 0, 0, 0.56);
}

.mui-success-green {
    color: #2e7d32 !important;
}

.item-list-quantity {
    font-size: 1rem;
    font-weight: 500;
    color: #2e7d32;
    /*margin-left: auto;*/
    margin-right: auto;
    border: #e0e0e0 1px solid;
    padding: 0 5px;
    border-radius: 5px;
}

.item-list-item {
    display: flex;
    flex-direction: row;
    /*justify-content: space-between;*/
    /*align-items: center;*/
    padding: 5px 0;
}

.item-list-name {
    font-weight: 650;
    font-size: 1.1rem;
}

/*.item-list-addon {*/
/*    color: */
/*}*/

.item-list-addon-price {
    margin-left: auto;
    font-weight: 500;
}

.item-list-addon-name {
    color: #6c757d;
    font-weight: 400;
}

.item-list-button {
    max-width: max-content;
    text-transform: initial !important;
    font-weight: 600 !important;
    font-size: 1rem;
}

a.item-list-button {
    font-size: 1rem !important;
}

.address-button-text {
    font-weight: 700;
    font-size: 1.1rem;
}

a.add-items-button {
    padding-right: 0;
    font-size: 1rem;
}

.cart-items-section h3 {
    margin: 0;
}

.text-no-transform {
    text-transform: none !important;
}

.text-strikethrough {
    text-decoration: line-through;
}

.mw-90 {
    max-width: 90% !important;
}

.min-width-0 {
    min-width: 0 !important;
}

.cart-spacer {
    height: 2px;
    background: #f4f4f4;
    width: 100%;
    margin: 3px auto;
}

.center-fixed {
    left: 50%;
    transform: translate(-50%, 0);
}

.header-spacer {
    height: 52px;
}

.btn-preference-large {
    min-width: 110px;
    font-size: 1rem;
    height: 2.1rem;
}

#schedulePopupDoneButtons {
    width: 97vw;
}

.gap-5 {
    gap: 5px;
}

.gap-10 {
    gap: 10px;
}

.gap-15 {
    gap: 15px;
}

.cursor-pointer {
    cursor: pointer;
}

.no-border-radius {
    border-radius: 0 !important;
}

.cursor-disabled {
    cursor: not-allowed;
}

.time-slots-holder {
    padding-bottom: 95px;
    display: flex;
    flex-direction: column;
}

.p-12 {
    padding: 12px;
}

.place-order-float {
    position: fixed;
    bottom: 0;
    /*left: 0;*/
    width: 100%;
    background: white;
    padding: 12px;
    box-shadow: 0 0 10px 0 #0000001a;
    z-index: 9;
    display: flex;
    flex-direction: column;
}

.place-order-float-spacer {
    height: 90px;
}
.place-order-float-spacer-large {
    height: 110px;
}

.total-price-title {
    font-weight: 600;
    font-size: 1.4rem;
    margin: 0
}

.total-price {
    font-weight: 800;
    font-size: 1.4rem;
    margin: 0
}

.pre-discount-price {
    font-size: 1rem;
    font-weight: 600;
    color: #3a3a3ad9;
    text-decoration: line-through;
}

.deemphasized-delete-button {
    opacity: 50%;
    display: flex;
}

.deemphasized-delete-button:hover {
    opacity: 100%;
}

.italics {
    font-style: italic;
}

.checkout-contactless-title {
    font-size: 1.3rem;
    margin-bottom: 0;
}

.cart-button-icon {
    width: 22px;
    /*max-width: 22px;*/
}

.flex-grow {
    flex-grow: 1;
}

.flex-shrink {
    flex-shrink: 1;
}

.px-12 {
    padding-left: 12px;
    padding-right: 12px;
}

.mx-12 {
    margin-left: 12px;
    margin-right: 12px;
}

.newCardButton span {
    font-size: 1.2rem;
}

.redeem-list-item-point-value {
    font-weight: 400;
    color: #616970;
    white-space: nowrap;
}

.cart-redeems-button-congratulations {
    margin-left: 27px;
}

button.btn-add-remove:disabled {
    background-color: rgb(25, 118, 210);
}

.delivery-instructions-contactless {
    font-weight: 400;
}

.schedule-select-button {
    font-weight: 600;
}
/*rewards start*/
.redeem-list {
    display: flex;
    flex-direction: column;
    /*padding: 10px;*/
}

.redeem-list-item {
    display: flex;
    flex-direction: row;
    gap: 5px;
    margin: 0 10px;
    border-bottom: 1px solid #e0e0e0;
    padding: 5px;
    border-radius: 5px;
}

.redeem-list-item:first-of-type {
    border-top: 1px solid #e0e0e0;
}

.placeholder {
    width: 80px;
    height: 80px;
    min-width: 80px;
}

.redeem-list-item-title {
    font-size: 1.15rem;
    font-weight: 700;
    color: #000000;
}

.redeem-list-item-description {

}

.redeem-list-right-block {
    margin-left: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.redeem-list-right-block > input {
    border-radius: 5px !important;
}

.redeem-list-item-image {
    width: 80px;
    object-fit: contain;
    align-self: start;
}

.open-rewards-menu-button {
    margin-left: auto;
    font-size: 1rem;
    text-transform: initial;
    display: inline-flex;
    align-items: center;
    flex-grow: 1;
}

.button-open-caret {
    margin-left: auto;
    height: 100% !important;
    width: 20px !important;
    font-size: 20px !important;
    fill: #6c757d;
}

.open-rewards-menu-button-text {
    display: flex;
    flex-grow: 1;
    width: 100%;
    padding-bottom: 3px;
    align-items: center;
}

.inline-food-runners-coin-image {
    height: 22px;
    object-fit: contain;
}


.accept-redeems-button {
    display: flex;
    position: fixed;
    width: 95%;
    left: 50%;
    transform: translateX(-50%);
    bottom: 10px;
}

.opacity-50 {
    opacity: 0.5;
}

.restaurant-blurb-popup-inner {

    font-size: 1.2rem;
    font-weight: 600;
    margin-right: 3px;
}

.restaurant-blurb-popup .MuiAlert-message, .restaurant-blurb-popup .MuiAlert-icon {
    padding: 0;
}

.restaurant-blurb-popup .MuiAlert-icon {
    margin-right: 5px;
}

.restaurant-blurb-popup {
    cursor: pointer;
}


.restaurant-blurb-popup-container {
    z-index: 10;
    position: fixed;
    bottom: 5vh;
    left: 0;
    right: 0;
    margin: 10vh auto;
    justify-content: center;
    /*transform: translate(-50%, -50%);*/
}

.redeem-list-page .MuiChip-label {
    padding-left: 8px;
}

.redeem-list-page {
    padding: 10px;
}

.redeem-list-title {
    margin: 10px;
    font-weight: 600;
    font-size: 1.2rem;

}

.runner-reward-background-color {
    background-color: #F64C72 !important;
}

.runner-reward-text-color {
    color: #F64C72 !important;
}

.add-address-details > div > div.text-truncate {
    display: flex;
    flex-direction: row;
    background-color: #f4f4f4;
    border-radius: 4px;
    align-items: center;
    padding: 3px 3px 3px 7px;
    flex-grow: 1;
}

.your-address-display {
    flex-grow: 1;
    height: 1lh;
    opacity: 1;
}

.width-100 {
    width: 100%;
}

.auth-reg-text-block {
    font-size: 1.1rem;
}


/*rewards end*/


#running-order-status-container {
    background-color: #ddd;
    height: 100%;
    display: flex;
    gap: 15px;
    flex-direction: column;
    max-height: calc(100vh - 455px);
}

#running-order-title {
    font-size: 20px;
    font-weight: 700;
}

#running-order-subtitle {
    font-size: 15px;
    color: gray;
}

#running-order-progress-bar-container {
    display: flex;
    flex-direction: row;
    gap: 5px;
    margin: 10px 0;
    max-width: 500px;
    align-items: center;
}

#running-order-restaurant-address-block:first-of-type,
#running-order-order-status-card:first-of-type {
    margin-top: 10px;
}

.running-order-card {
    padding: 7px 10px;
    margin: 0 10px;
}

#running-order-restaurant-address-block {
    margin: 0 15px;
}

.running-order-progress-bar-highlighted {
    background-color: rgb(55, 0, 179) !important;
}

/*.running-order-progress-bar-highlighted:nth-last-child(1 of .running-order-progress-bar-highlighted) {*/
/*    background: linear-gradient(to right,*/
/*    rgb(36, 37, 130),*/
/*    rgb(36, 37, 130) 50%,*/
/*    #7a7a7a 50%,*/
/*    #7a7a7a);*/
/*}*/

/*COMMENTED DUE TO CHROME BUG!*/
/*On chrome, the above rule is not correctly applied when the page is updated by react.*/
/*It is updated when the element is re-rendered for any reason.*/

.running-order-progress-bar {
    height: 3px;
    border-radius: 5px;
    background-color: #7a7a7a;
    flex: 1;
}

@media screen and (width >= 1000px) {
    /*body {*/
    /*    background: #9d9d9d;*/
    /*}*/
    body {
        max-width: 1000px;
        margin: auto;
        background: white;
        /*background: rgba(204, 204, 204, 0.09);*/
    }

    .footer-fixed {
        width: 1000px !important;
    }

    .cart-checkout-block, .btn-make-payment, .input-group,
    .block-content, .float-cart, .overlay-loading, .accept-redeems-button,
    .btn-refresh-status, .running-order-container, .map-display, .btn-new-address,
    .btn, .btn-save-address, .confirm-gps-location, .auth-error, .place-order-float,
    #customizationBottomContainer {
        max-width: 1000px !important;
    }

    .block-content {
        margin: 0 !important;
    }

    .col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col, .col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm, .col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md, .col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg, .col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl, .col-xl-auto {
        max-width: 1000px !important;
    }

    .addon-category-name {
        border-radius: 15px;
    }

    #schedulePopup {
        margin: 0 !important;
        bottom: -25vh !important;
    }

    #schedulePopupDoneButtons {
        max-width: 581px !important;
    }

}