html {
	overflow: scroll;
	overflow-x: hidden;
}


body {
	color: #282c3f !important;
	background-color: #fff;
}
html,
body {
	font-size: 13px;
}
.bg-grey {
	background-color: #f4f4f4 !important;
}
.bg-grey-light {
	background-color: rgba(250, 250, 250, 0.5) !important;
}
.bg-white {
	background-color: #fff !important;
}
.hidden {
	display: none !important;
}
.spinner {
	color: rgba(252, 128, 25, 0.15);
}
.height-25 {
	height: 25vh;
}
.height-30 {
	height: 30vh;
}
.height-40 {
	height: 40vh;
}
.height-50 {
	height: 50vh;
}
.height-60 {
	height: 60vh;
}
.height-70 {
	height: 70vh;
}
.height-80 {
	height: 82vh;
}
.height-100 {
	height: 100vh;
}
.height-100-percent {
	height: 100% !important;
	min-height: 100vh !important;
}
.fixed {
	position: fixed;
	pointer-events: all;
}
.truncate-text {
	white-space: nowrap;
	overflow: hidden;
	-o-text-overflow: ellipsis;
	text-overflow: ellipsis;
}
.shadow-light {
	-webkit-box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.05);
	box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.05);
	-webkit-transition: 0.2s linear all;
	-o-transition: 0.2s linear all;
	transition: 0.2s linear all;
}
.shadow-medium {
	-webkit-box-shadow: 0 8px 18px rgba(0, 0, 0, 0.15);
	box-shadow: 0 8px 18px rgba(0, 0, 0, 0.15);
}
.shadow-dark {
	-webkit-box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25);
	box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25);
}
.display-flex {
	display: -webkit-box !important;
	display: -ms-flexbox !important;
	display: flex !important;
}
.flex-auto {
	-webkit-box-flex: 1;
	-ms-flex: auto;
	flex: auto;
}
/* FirstScreen */

.welcome-heading {
	text-align: center;
	margin: 0.25rem 1.5625rem 0;
	font-size: 1.5rem;
	line-height: 1.75rem;
	font-weight: 700;
	white-space: pre-wrap;
	color: #282c3f;
}
.welcome-subheading {
	font-size: 0.875rem;
	color: #646464;
	margin-top: 0.9375rem;
}
.btn-setup-location {
	width: 16rem;
	height: 4rem;
	margin: 0 auto;
	font-size: 1.1rem;
	font-weight: 700;
	display: block;
	color: #fff;
	text-transform: uppercase;
	border-radius: 1px;
	text-align: center;
	line-height: 2.7rem;
}
.btn-setup-location:hover {
	color: #fff;
}
.login-block {
	display: block;
	text-align: center;
	color: #646464;
	margin-top: 0.9375rem;
}
.login-block > a {
	font-weight: 400;
}
/* Location */

.search-box {
	top: -1px;
	-webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
	box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
}
.search-input {
	font-size: 0.9375rem;
	height: 4rem;
	border: 0;
}
.search-navs-btns {
	height: 4rem;
	border: 0;
	width: 40px;
	background: #fff;
	border-radius: 0 !important;
}
.search-navs-btns > i {
	font-weight: 900;
}
.height-8-5 {
	height: 8.5vh;
}
.location-search-result {
	min-height: auto;
	background-color: #fff;
}
.location-search-block {
	border-top: 1px solid #eaecee;
	position: relative;
	padding: 1.4rem 0;
	background-color: rgba(0, 0, 0, 0.02);
}
.location-search-block:nth-of-type(2n-1) {
	background-color: #fff;
}
.location-search-icon {
	width: 5rem;
	float: left;
	text-align: center;
}
.location-search-data {
	padding-left: 5.625rem;
}
table > tbody > tr {
	height: 6rem;
}
/*Footer */

.footer-fixed {
	position: fixed;
	bottom: -1px;
	-webkit-box-shadow: 0px -2px 6px 1px rgba(0, 0, 0, 0.15);
	box-shadow: 0px -2px 6px 1px rgba(0, 0, 0, 0.15);
	display: -webkit-inline-box;
	display: -ms-inline-flexbox;
	display: inline-flex;
	background-color: #fff;
	text-align: center;
	z-index: 2;
	width: 101%;
}
.footer-float {
	position: fixed;
	bottom: 7px;
	box-shadow: 0 2px 25px 12px rgba(0, 0, 0, 0.09);
	display: inline-flex;
	background-color: #fff;
	text-align: center;
	z-index: 2;
	width: 95%;
	border-radius: 0.6rem;
}
.active-footer-tab {
	color: #fc8019;
	font-weight: 600;
}
.footer-links {
	color: #4d4d4d;
	font-weight: 500;
}
.cart-quantity-badge {
	border-radius: 100%;
	color: #fff;
	position: absolute;
	top: 0px;
	left: 50%;
	font-size: 10px;
	text-align: center;
	height: 16px;
	width: 16px;
}
/* Nav */

.nav-location {
	height: 4rem;
	border: 0;
	background: #fff;
	border-radius: 0;
}
.nav-location-icon {
	font-size: 0.625rem;
	margin-left: 0.125rem;
	font-weight: 700;
}
/* Promo Slider */

.slider-wrapper {
	overflow-x: scroll;
	overflow-y: hidden;
	white-space: nowrap;
	scroll-behavior: smooth;
	-webkit-overflow-scrolling: touch;
}

.slider-wrapper__img-wrapper {
	display: inline-block;
	margin-left: 0.5rem;
	margin-right: 0.5rem;
}
.slider-wrapper__img {
	border-radius: 0.4rem;
}
.slider-wrapper__img-shadow {
	-webkit-box-shadow: 1px 2px 10px 1px #e0e0e0;
	box-shadow: 1px 2px 10px 1px #e0e0e0;
}
.slider-wrapper__img-wrapper:first-child {
	margin-left: 1rem;
}
.slider-wrapper__img-wrapper:last-child {
	margin-right: 1rem;
}
.promo-slider-loader {
	width: 100%;
	margin: 0;
	height: 192px;
}
/* RestaurantList */

.restaurant-count {
	text-transform: uppercase;
	color: #5b5b5b;
	font-size: 0.78rem;
	font-weight: 700;
}
.restaurant-block {
	/*padding: 0 !important;*/
	/* -webkit-box-shadow: 0 1px rgba(0, 0, 0, 0.03);
    box-shadow: 0 1px rgba(0, 0, 0, 0.03); */
}
.restaurant-block > div {
	margin-bottom: 0px !important;
}
.restaurant-image {
	width: 80px;
	height: 80px;
	float: left;
	height: 5.625rem;
	border-radius: 0.25rem;
	margin-top: 1rem;
}
.restaurant-info {
	margin-top: -1.8rem;
	text-align: left;
	padding-left: 8.5rem;
	padding-bottom: 1rem !important;
}
.restaurant-info > h4 {
	font-size: 1.2rem;
}
.restaurant-meta {
	margin-top: -1.2rem;
	font-size: 0.8rem;
	font-weight: 500;
}
.block-content.block-content-full {
	padding-bottom: 5px;
}
/* Auth/Login */

.back-button {
	background-color: transparent;
}

.login-title {
	font-size: 1.3rem;
	font-weight: 700;
	text-transform: uppercase;
}
.login-subtitle {
	font-size: 1rem;
	font-weight: 400;
}
.overlay-loading {
	position: fixed;
	width: 100%;
	background-color: #fff;
	z-index: 9999;
}
.overlay-loading > div {
	left: 50%;
	top: 50%;
	-webkit-transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	position: absolute;
}
.overlay-loading > div > img {
	width: 300px;
}
.auth-error {
	position: fixed;
	bottom: 0;
	text-align: center;
	width: 100%;
	background-color: #282c3f;
	color: #f4f4f5;
	padding: 0.8rem;
	z-index: 2147483647;
}
.error-shake {
	-webkit-animation: shake 0.85s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
	animation: shake 0.85s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
	-webkit-transform: translate3d(0, 0, 0);
	transform: translate3d(0, 0, 0);
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
	-webkit-perspective: 1000px;
	perspective: 1000px;
}
@-webkit-keyframes shake {
	10%,
	90% {
		-webkit-transform: translate3d(-3px, 0, 0);
		transform: translate3d(-3px, 0, 0);
	}

	20%,
	80% {
		-webkit-transform: translate3d(5px, 0, 0);
		transform: translate3d(5px, 0, 0);
	}

	30%,
	50%,
	70% {
		-webkit-transform: translate3d(-1px, 0, 0);
		transform: translate3d(-1px, 0, 0);
	}

	40%,
	60% {
		-webkit-transform: translate3d(7px, 0, 0);
		transform: translate3d(7px, 0, 0);
	}
}
@keyframes shake {
	10%,
	90% {
		-webkit-transform: translate3d(-3px, 0, 0);
		transform: translate3d(-3px, 0, 0);
	}

	20%,
	80% {
		-webkit-transform: translate3d(5px, 0, 0);
		transform: translate3d(5px, 0, 0);
	}

	30%,
	50%,
	70% {
		-webkit-transform: translate3d(-1px, 0, 0);
		transform: translate3d(-1px, 0, 0);
	}

	40%,
	60% {
		-webkit-transform: translate3d(7px, 0, 0);
		transform: translate3d(7px, 0, 0);
	}
}
/* Elements/Button */

.button-block {
	width: 100%;
}
.btn-main {
	width: 100%;
	height: 4rem;
	font-size: 1.2rem;
	border-radius: 0;
	color: #fff;
	font-weight: 600;
	text-transform: uppercase;
}
/* Items/Restaurantinfo */

.fixed-restaurant-info {
	position: absolute;
	top: 0.6rem;
	left: 5.2rem;
}
.fixedRestaurantName {
	font-size: 1rem;
	font-weight: 700;
}
.fixedRestaurantTime {
	font-weight: 400;
	font-size: 0.9rem;
}
/* Items/ItemList */

.recommended-item-content {
	padding: 0.5rem;
	height: 14rem;
	min-height: 16rem;
}
.recommended-item-shadow {
	-webkit-box-shadow: 0px -5px 12px 1px rgba(0, 0, 0, 0.05);
	box-shadow: 0px -5px 12px 1px rgba(0, 0, 0, 0.05);
}
.recommended-item-image {
	border-radius: 4px;
	height: 131px;
	width: 180px;
}
.recommended-item-meta {
	height: 4.5rem;
	min-height: 4.5rem;
}
.meta-name {
	font-size: 0.95rem;
	font-weight: 500;
	max-width: 169px;
	display: inline-block;
	overflow: hidden;
	white-space: nowrap;
	-o-text-overflow: ellipsis;
	text-overflow: ellipsis;
}
.meta-price {
	font-size: 0.95rem;
	line-height: 1.5625rem;
}
.Collapsible {
	background-color: white;
}
.Collapsible__contentInner {
	padding: 10px;
}
.Collapsible__contentInner .category-list-item {
	margin-bottom: 0.625rem;
	font-size: 0.98rem;
	margin-bottom: 1.5rem;
	border-bottom: 1px solid #f5f5f5;
	padding-bottom: 0.8rem;
	padding-left: 0.3125rem;
}
.Collapsible__contentInner .category-list-item:last-child {
	margin-bottom: 0;
	border: 0;
}
.Collapsible__contentInner > div > .item-name {
	font-weight: 500;
}
.Collapsible__contentInner > div > .item-price {
	font-weight: 400;
	color: #757575;
}
.Collapsible__trigger {
	display: block;
	text-decoration: none;
	position: relative;
	padding: 1.5rem 1rem;
	background: #ffffff;
	color: #282c3f;
	border-bottom: 1px solid #eaeaea;
	margin-bottom: 0.5rem;
	font-weight: 600;
}
.Collapsible__trigger:after {
	font-family: "FontAwesome";
	content: "\f107";
	position: absolute;
	right: 1.025rem;
	top: 1.425rem;
	display: block;
	-webkit-transition: -webkit-transform 300ms;
	transition: -webkit-transform 300ms;
	-o-transition: transform 300ms;
	transition: transform 300ms;
	transition: transform 300ms, -webkit-transform 300ms;
}
.Collapsible__trigger.is-open:after {
	-webkit-transform: rotateZ(180deg);
	-ms-transform: rotate(180deg);
	transform: rotateZ(180deg);
}
.item-badge {
	position: relative;
	color: #fafafa;
	font-size: 0.6rem;
	padding: 0.25rem 0.4rem;
	text-transform: none;
	font-weight: 600;
	border-radius: 4px;
}
.item-badge:not(:first-child) {
	margin-left: 0.25rem;
}

.btn-add-remove {
	border: 1px solid #efefef;
	/*width: 3.7rem;*/
	background-color: #fff;
}
.btn-full .btn-add-remove {
	width: 82px !important;
}
.btn-dec {
	font-size: 1.7rem;
	font-weight: 500;
	line-height: 18px;
	color: #282c3f;
}
.btn-inc {
	font-size: 1.4rem;
	line-height: 14px;
	color: #282c3f;
	font-weight: 500;
}
.flex-item-name {
	-webkit-box-flex: 1;
	-ms-flex-positive: 1;
	flex-grow: 1;
	border: 0 !important;
}
.flex-item-image {
	border-radius: 0.25rem;
	width: 88px;
	position: relative;
}
.recommended-text {
	font-size: 1rem;
	text-transform: uppercase;
}
/* FloatCart */

.float-cart {
	position: fixed;
	width: 100%;
	height: 4rem;
	padding: 1.2rem;
	font-weight: 700;
	opacity: 0;
	bottom: 0rem;
	-webkit-transition: 0.2s all linear;
	-o-transition: 0.2s all linear;
	transition: 0.2s all linear;
	z-index: 9;
}
.float-cart--open {
	opacity: 1;
	bottom: -0.2rem;
	-webkit-transition: 0.2s all linear;
	-o-transition: 0.2s all linear;
	transition: 0.2s all linear;
}
/* Elements/BackWithSearch*/

.nav-page-title {
	font-size: 1.3rem;
	font-weight: 600;
	text-align: center;
	/*text-transform: uppercase;*/
	line-height: 42px;
	margin: 0 auto;
}
/* Cart */

.cart-empty-block {
	height: auto;
	padding-top: 100px;
	display: flex;
	flex-direction: column;
	align-items: center;
}
.cart-empty-img {
	width: 300px;
	height: intrinsic;
}
.cart-empty-text {
	font-size: 1.2rem;
	font-weight: 600;
	color: #535665;
}
.progress-transparent {
	background-color: transparent !important;
}
/* Cart/CartItems */

.btn-quantity {
	height: 2.5rem !important;
	background-color: #fff;
	border: 1px solid #efefef;
	min-width: 35px;
}
.cart-item-meta {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
}
.cart-item-name {
	-webkit-box-flex: 1;
	-ms-flex: auto;
	flex: auto;
	width: 110px;
	font-weight: 400;
}
.cart-item-btn {
	-webkit-box-flex: 1;
	-ms-flex: auto;
	flex: auto;
	width: 110px;
	margin-top: -0.5rem;
}
.cart-item-price {
	font-weight: 500;
	font-weight: 400;
	width: 75px;
	text-align: right;
}
.item-text {
	font-size: 1rem;
	text-transform: uppercase;
	border-bottom: 1px solid #f2f2f2;
	padding-bottom: 1rem;
}
/* Cart/OrderComment */

.order-comment {
	height: 3.2rem;
	font-size: 1rem;
	border: 0;
	padding: 0 15px;
	border-radius: 0;
	border-bottom-left-radius: 5px;
	border-bottom-right-radius: 5px;
	box-shadow: 0px 8px 10px 2px #eee;
}
/* Cart/Coupon */

.coupon-form {
	width: 100%;
}
.apply-coupon-input {
	height: 4rem;
	font-size: 1.1rem;
	border: 0;
	padding: 0 15px;
	font-weight: 600;
	text-transform: uppercase;
	letter-spacing: 1px;
}
.apply-coupon-input::placeholder {
	color: #282c3f;
	letter-spacing: 1px;
}

.apply-coupon-btn {
	height: 4rem;
	background-color: #fff;
	min-width: 4rem;
	border: 0;
	text-align: center;
}
.apply-coupon-btn > span {
	padding: 0.6rem 2rem;
	border-radius: 4px;
}
.input-group-prepend > .apply-coupon-btn {
	border-top-left-radius: 5px;
	border-bottom-left-radius: 5px;
}
.input-group-append > .apply-coupon-btn {
	border-top-right-radius: 5px;
	border-bottom-right-radius: 5px;
}

.coupon-status {
	width: 100%;
	text-align: center;
}
.coupon-success {
	background-color: #60b246;
	color: #fff;
	font-weight: 500;
	border-bottom-left-radius: 5px;
	border-bottom-right-radius: 5px;
	border: 0;
}
.coupon-fail {
	background-color: #f44336;
	color: #fff;
	font-weight: 500;
	border-bottom-left-radius: 5px;
	border-bottom-right-radius: 5px;
	border: 0;
}
.coupon-text {
	color: #60b246;
	font-weight: 500;
}
/* Cart/BillDetails */
.border-radius-4px {
	border-radius: 20px;
}

.bill-details::after {
	content: " ";
	background: -o-linear-gradient(135deg, #f4f4f5 7px, transparent 0),
	-o-linear-gradient(45deg, #f4f4f5 7px, transparent 0);
	background: linear-gradient(-45deg, #f4f4f5 7px, transparent 0), linear-gradient(45deg, #f4f4f5 7px, transparent 0);
	background-position: left bottom;
	background-repeat: repeat-x;
	background-size: 12px 14px;
	display: block;
	left: 0;
	width: 100%;
	height: 14px;
	bottom: 0;
}
.bill-detail-text {
	height: 3.2rem;
	font-size: 1.1rem;
	border: 0;
	text-transform: uppercase;
}
/* Cart/CartCheckoutBlock */

.cart-checkout-block {
	width: 100%;
	position: fixed;
	bottom: 0;
	z-index: 9;
	height: 22vh;
	-webkit-box-shadow: 0px -2px 12px 2px rgba(0, 0, 0, 0.06);
	box-shadow: 0px -2px 12px 2px rgba(0, 0, 0, 0.06);
}
.deliver-to-text {
	font-size: 1rem;
	text-transform: uppercase;
	padding-bottom: 1rem;
	font-weight: 600;
}
.change-address-text {
	font-size: 0.8rem;
	text-transform: uppercase;
	padding-bottom: 1rem;
	font-weight: 600;
	border: 1px solid #e0e0e0;
	background-color: #f3f3f3;
}
.user-address {
	font-size: 13px;
}
.almost-there-text {
	font-size: 1.2rem;
	text-transform: uppercase;
	padding-bottom: 1rem;
	font-weight: 700;
}
.almost-there-sub {
	font-weight: 400;
	font-size: 0.9rem;
}
.btn-continue {
	width: 100%;
	border-radius: 0;
	height: 4rem;
	margin: 0 auto;
	font-size: 1.1rem;
	font-weight: 700;
	display: block;
	color: #fff;
	text-transform: uppercase;
	text-align: center;
	line-height: 2.7rem;
	bottom: 0;
	position: fixed !important;
	margin-left: -1.1rem;
}
.btn-continue:focus,
.btn-continue:hover {
	color: #fff !important;
}
.btn-make-payment {
	width: 100%;
	margin: 0 auto;
	padding: 0.9375rem 0;
	line-height: 1.95rem;
	font-size: 1.1rem;
	font-weight: 700;
	display: block;
	text-transform: uppercase;
	border-radius: 0;
	height: 4rem;
	bottom: 0;
	position: fixed !important;
}
.btn-make-payment:focus,
.btn-make-payment:hover {
	color: #fff !important;
}
/* Account/UserInfo */

.hr-bold {
	border-top: 2px solid #dfdfdf !important;
}
/* Account/UserMenu */

.pages-modal {
	overflow: scroll;
	min-height: 100vh;
}
.popup-overlay {
	padding: 0 !important;
}
.popup-content {
	width: 100% !important;
	border: 0 !important;
	padding: 10px !important;
	padding-top: 0 !important;
}
.close-modal-header {
	position: fixed;
	height: 4rem;
	top: 0;
	width: 100%;
	right: 10px;
	background-color: #fff;
	border: 0 !important;
}
.close-modal-icon {
	font-size: 2.5rem;
	font-weight: 200;
}
/* Account/Logout */

.logout-text {
	color: #f44336;
	font-weight: 600;
}
.logout-icon {
	color: #f44336;
	font-weight: 900;
}
/* Account/Addresses */

.btn-new-address {
	position: fixed !important;
	width: 100%;
	margin: 0 auto;
	padding: 0.9375rem 0;
	line-height: 2.15rem;
	font-size: 1.2rem;
	font-weight: 600;
	display: block;
	text-transform: uppercase;
	border-radius: 0;
	bottom: 0;
	color: #fff;
	text-align: center;
}
.btn-save-address {
	position: relative;
	width: 100%;
	margin: 0 auto;
	padding: 0.9375rem 0;
	line-height: 2.15rem;
	font-size: 1.2rem;
	font-weight: 600;
	display: block;
	text-transform: uppercase;
	border-radius: 0;
	color: #fff;
	text-align: center;
	border: 0;
	cursor: pointer;
}
.edit-address-input-label {
	color: #9e9e9e;
}
.edit-address-input {
	margin-bottom: 1.5rem;
	border: 0;
	border-bottom: 1.2px solid #eee;
	border-radius: 0;
	padding: 0;
}
.edit-address-tag {
	text-transform: uppercase;
}
.btn-edit-address {
	font-weight: 500;
}
/* Address/AddressList */

.btn-address-default {
	background-color: transparent;
	position: relative;
}
.btn-address-default > i {
	color: #ccc;
}
.btn-address-default.selected > i {
	font-size: 2rem;
}
/* RunningOrder */
.btn-deliverypin {
	color: #ff9800;
}
.btn-refresh-status {
	width: 100%;
	position: fixed;
	bottom: 0;
	border: 0;
	border-radius: 0;
	height: 4rem;
	font-size: 1.2rem;
}
/* Account/Orders/OrderList */

.order-item-quantity {
	border: 1px solid #e0e0e0;
	padding: 2px 8px;
	font-weight: 500;
	min-width: 35px;
	text-align: center;
}
/* Desktop View */

.main-container-desktop {
	min-height: 100vh;
	background: -o-linear-gradient(
			135deg,
			rgba(103, 58, 183, 0.9),
			rgba(255, 87, 34, 0.7),
			rgba(103, 58, 183, 0.9),
			rgba(255, 87, 34, 0.7)
	);
	background: linear-gradient(
			-45deg,
			rgba(103, 58, 183, 0.9),
			rgba(255, 87, 34, 0.7),
			rgba(103, 58, 183, 0.9),
			rgba(255, 87, 34, 0.7)
	);
	background-size: 500% 500%;
	-webkit-animation: Gradient 50s ease infinite;
	animation: Gradient 50s ease infinite;
	position: relative;
	z-index: 1;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	overflow: hidden;
}
.main-container-desktop::before {
	content: "";
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	position: absolute;
	background: url(/assets/img/various/bg-design.png) no-repeat scroll center bottom/cover;
	z-index: -1;
}
.phone {
	-ms-flex-negative: 0;
	flex-shrink: 0;
	-webkit-box-shadow: 0 0 20px 10px rgba(0, 0, 0, 0.2);
	box-shadow: 0 0 20px 10px rgba(0, 0, 0, 0.2);
	width: 380px;
	height: 665px;
	padding: 10px 7px;
	border-radius: 20px;
	background: #d9dee3;
	-ms-grid-row-align: center;
	-ms-flex-item-align: center;
	align-self: center;
	margin: -3.5rem 0 0 12rem;
	position: relative;
	-webkit-transform: rotate(-15deg);
	-ms-transform: rotate(-15deg);
	transform: rotate(-15deg);
	-webkit-transition: 0.3s linear all;
	-o-transition: 0.3s linear all;
	transition: 0.3s linear all;
}
.phone-use {
	width: 380px;
	height: 665px;
	margin: -3rem 0 0 8rem;
	-webkit-transition: 0.3s linear all;
	-o-transition: 0.3s linear all;
	transition: 0.3s linear all;
	-webkit-transform: rotate(0deg);
	-ms-transform: rotate(0deg);
	transform: rotate(0deg);
}
.phone .notch {
	position: absolute;
	height: 1rem;
	width: 28%;
	background-color: #d9dee3;
	z-index: 9;
	right: 50%;
	-webkit-transform: translate(50%, -5%);
	-ms-transform: translate(50%, -5%);
	transform: translate(50%, -5%);
	border-bottom-left-radius: 2rem;
	border-bottom-right-radius: 2rem;
}
.phone .iframe-wrapper {
	position: relative;
	border-radius: 12px;
	overflow: hidden;
	height: 100%;
}
.phone iframe {
	height: 100%;
	display: block;
	width: 100%;
	border-radius: 12px;
}
.blured {
	-webkit-filter: grayscale(0.8);
	filter: grayscale(0.8);
}
.btn-unblur {
	height: 4.5rem;
	width: 200px;
	background-color: #f1592a;
	text-align: center;
	font-size: 1.5rem;
	font-weight: 500;
	color: #fff;
	border-radius: 0.275rem;
	line-height: 4rem;
	-webkit-transition: 0.2s linear all;
	-o-transition: 0.2s linear all;
	transition: 0.2s linear all;
}
.btn-unblur:hover {
	background-color: #f1593a;
	-webkit-box-shadow: 0px 12px 32px rgba(0, 0, 0, 0.4);
	box-shadow: 0px 12px 32px rgba(0, 0, 0, 0.4);
	cursor: pointer;
	-webkit-transition: 0.2s linear all;
	-o-transition: 0.2s linear all;
	transition: 0.2s linear all;
}
#appIframe {
	pointer-events: none;
}
.phone-use #appIframe {
	pointer-events: auto !important;
}
.bg-gradient {
	background: -o-linear-gradient(135deg, rgba(103, 58, 183, 0.9), rgba(0, 150, 136, 0.9), rgba(103, 58, 183, 0.9));
	background: linear-gradient(-45deg, rgba(103, 58, 183, 0.9), rgba(0, 150, 136, 0.9), rgba(103, 58, 183, 0.9));
	background-size: 400% 400%;
	-webkit-animation: Gradient 30s ease infinite;
	animation: Gradient 30s ease infinite;
}
@-webkit-keyframes Gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}
@keyframes Gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}
.left-col {
	-webkit-transform: translate(0px, 20%);
	-ms-transform: translate(0px, 20%);
	transform: translate(0px, 20%);
}
.desktop-logo {
	margin-bottom: 2.5rem;
	height: 3.5rem;
}

.desktop-title {
	color: #424242;
	font-size: 3.5rem;
	font-weight: 900;
}
.desktop-subtitle {
	color: #424242;
	font-size: 1.3rem;
	font-weight: 400;
}

.store-achievement {
	width: 200px;
	height: 150px;
	-webkit-box-shadow: rgba(34, 48, 102, 0.1) -3px 6px 21.1px -4px;
	box-shadow: rgba(34, 48, 102, 0.1) -3px 6px 21.1px -4px;
	border-radius: 7px;
	background-color: #fff;
	-webkit-transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s;
	-o-transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s;
	transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s;
}
.store-achievement:hover {
	-webkit-transform: translateY(0, -10%);
	-ms-transform: translateY(0, -10%);
	transform: translateY(0, -10%);
	-webkit-box-shadow: rgba(34, 48, 102, 0.1) -3px 6px 21.1px -10px;
	box-shadow: rgba(34, 48, 102, 0.1) -3px 6px 21.1px -10px;
}
.Tilt {
	-webkit-transform-style: preserve-3d;
	transform-style: preserve-3d;
}
.store-achievement h3 {
	font-weight: 900;
	color: #424242;
	margin-bottom: 0.45rem;
}

.store-achievement h4 {
	-webkit-transform: translateZ(15px);
	transform: translateZ(15px);
	color: #424242;
}

.desktop-footer {
	min-height: 70vh;
	background: -o-linear-gradient(
			135deg,
			rgba(255, 87, 34, 0.7),
			rgba(103, 58, 183, 0.9),
			rgba(255, 87, 34, 0.7),
			rgba(103, 58, 183, 0.9)
	);
	background: linear-gradient(
			-45deg,
			rgba(255, 87, 34, 0.7),
			rgba(103, 58, 183, 0.9),
			rgba(255, 87, 34, 0.7),
			rgba(103, 58, 183, 0.9)
	);
	background-size: 500% 500%;
	-webkit-animation: Gradient 50s ease infinite;
	animation: Gradient 50s ease infinite;
	position: relative;
	z-index: 1;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	overflow: hidden;
}
.desktop-footer::before {
	content: "";
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	position: absolute;
	background: url(/assets/img/various/bg-design.png) no-repeat scroll center bottom/cover;
	z-index: -1;
	-webkit-transform: rotate(-180deg);
	-ms-transform: rotate(-180deg);
	transform: rotate(-180deg);
}
.footer-logo {
	height: 2rem;
}
/* END Desktop View */

/* Delivery/Orders */
.delivery {
	position: relative;
	border-radius: 0.275rem;
	-webkit-box-shadow: 0 2px 4px 2px #eeeeee;
	box-shadow: 0 2px 4px 2px #eeeeee;
	margin-bottom: 1.5rem;
}

.btn-logout-delivery {
	width: 100%;
	border-radius: 0;
	height: 4rem;
	margin: 0 auto;
	font-size: 1.1rem;
	font-weight: 700;
	display: block;
	color: #fff;
	text-transform: uppercase;
	text-align: center;
	line-height: 2.7rem;
	bottom: 0;
	position: fixed !important;
	background-color: #000;
}
.delivery > h3 {
	color: #575757;
	text-transform: uppercase;
	font-size: 1.2rem;
}

.delivery .accepted {
	background-color: #4caf50;
	border-top-left-radius: 0.275rem;
	border-top-right-radius: 0.275rem;
}
.delivery .new-order {
	background-color: #ff9800;
	border-top-left-radius: 0.275rem;
	border-top-right-radius: 0.275rem;
}
.delivery .block-title {
	color: #fff;
	font-weight: 700;
	font-size: 1rem;
}
.delivery-action {
	position: fixed;
	width: 100%;
	bottom: 0;
	z-index: 99;
}
.delivery .block-content {
	padding: 10px 10px 1px;
}
.delivery-time,
.delivery-earnings {
	font-size: 0.9rem;
}
.btn-accept {
	position: fixed;
	bottom: 0;
	left: 0;
	width: 100%;
	border-radius: 0;
	border: 0;
	height: 4rem;
	font-size: 1.2rem;
	color: #fff;
}
.btn-get-direction {
	border-radius: 1px;
	background-color: #fff;
	border: 1px solid #e0e0e0;
}
.btn-clock {
	color: #ff5722;
	width: 100%;
}
.btn-cod {
	width: 100%;
	height: 4rem;
	font-size: 1.2rem;
	background-color: #192038;
	color: #fff;
	border: 2px dashed #8360c3;
	border-radius: 50px;
}
.btn-payed-online {
	width: 100%;
	height: 4rem;
	font-size: 1.2rem;
	background-color: #fff;
	border: 3px dashed #60b246;
	border-radius: 0;
}
.delivery-pin-block input {
	text-transform: uppercase;
	width: 100%;
	height: 4rem;
	font-size: 1.2rem;
	background-color: rgba(0, 0, 0, 0.4);
	color: #fafafa;
	border: 0;
	border-radius: 4px;
	transition: 0.2s linear all;
	text-align: center;
}
.delivery-pin-block input:focus {
	background-color: rgba(0, 0, 0, 0.6);
	color: #fafafa;
	transition: 0.2s linear all;
}
.delivery-pin-block .btn-submit-pin {
	height: 3.2rem;
	width: 100%;
	border-radius: 0;
}
/* Delivery/ViewOrder */

.view-delivery-order h3 {
	font-size: 1.3rem;
	text-transform: uppercase;
}
.restaurant-address,
.customer-address {
	border: 1px solid #e0e0e0;
	padding: 1rem;
	background-color: #fff;
}

/* EXTRAS */

.fixed-gdpr {
	min-height: 3rem;
	height: auto;
	text-align: center;
	padding-top: 0.6rem;
	background-color: #282c3f;
	color: #fff;
}
.fixed-gdpr-mobile {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	padding: 5px 15px;
	min-height: 0rem;
	height: auto;
	text-align: center;
	padding-top: 0.6rem;
	background-color: #282c3f;
	color: #fff;
	position: fixed;
	bottom: 0;
	z-index: 99999999;
}
.fixed-gdpr-mobile button {
	color: #fff;
}
.fixed-gdpr button {
	color: #fff;
}

.mobile-gdpr {
	position: fixed;
	bottom: 0;
	height: 6rem;
	color: #fff;
	left: 0;
	padding: 10px;
	-webkit-box-shadow: 0px -5px 10px 2px rgba(0, 0, 0, 0.2);
	box-shadow: 0px -5px 10px 2px rgba(0, 0, 0, 0.2);
	z-index: 9999999;
	background-color: #282c3f;
}
.mobile-gdpr button {
	color: #fff;
	padding: 3px 12px;
	font-size: 1.1rem;
	font-weight: 400;
	opacity: 1;
}

.mobile-gdpr button:hover {
	text-shadow: none;
	color: #fff;
}

/* Addons Popup*/
@-webkit-keyframes hover-color {
	from {
		border-color: #c0c0c0;
	}
	to {
		border-color: #3e97eb;
	}
}
@keyframes hover-color {
	from {
		border-color: #c0c0c0;
	}
	to {
		border-color: #3e97eb;
	}
}

.magic-radio,
.magic-checkbox {
	position: absolute;
	height: 3rem;
	width: 100%;
	opacity: 0;
	z-index: 1001;
}

.magic-radio[disabled],
.magic-checkbox[disabled] {
	cursor: not-allowed;
}

.magic-radio + label,
.magic-checkbox + label {
	position: relative;
	display: block;
	cursor: pointer;
	vertical-align: middle;
	line-height: 30px;
	max-width: 85%;
}
.magic-radio + label:hover:before,
.magic-checkbox + label:hover:before {
	-webkit-animation-duration: 0.4s;
	animation-duration: 0.4s;
	-webkit-animation-fill-mode: both;
	animation-fill-mode: both;
	-webkit-animation-name: hover-color;
	animation-name: hover-color;
}
.magic-radio + label:before,
.magic-checkbox + label:before {
	position: absolute;
	top: 0;
	right: -20%;
	display: inline-block;
	width: 30px;
	height: 30px;
	content: "";
	border: 2px solid #e7e7e7;
}
.magic-radio + label:after,
.magic-checkbox + label:after {
	position: absolute;
	display: none;
	content: "";
}

.magic-radio[disabled] + label,
.magic-checkbox[disabled] + label {
	cursor: not-allowed;
	color: #e4e4e4;
}
.magic-radio[disabled] + label:hover,
.magic-radio[disabled] + label:before,
.magic-radio[disabled] + label:after,
.magic-checkbox[disabled] + label:hover,
.magic-checkbox[disabled] + label:before,
.magic-checkbox[disabled] + label:after {
	cursor: not-allowed;
}
.magic-radio[disabled] + label:hover:before,
.magic-checkbox[disabled] + label:hover:before {
	border: 1px solid #e4e4e4;
	-webkit-animation-name: none;
	animation-name: none;
}
.magic-radio[disabled] + label:before,
.magic-checkbox[disabled] + label:before {
	border-color: #e4e4e4;
}

.magic-radio:checked + label:before,
.magic-checkbox:checked + label:before {
	-webkit-animation-name: none;
	animation-name: none;
}

.magic-radio:checked + label:after,
.magic-checkbox:checked + label:after {
	display: block;
}

.magic-radio + label:before {
	border-radius: 50%;
}

.magic-radio + label:after {
	top: 9px;
	right: -17.1%;
	width: 12px;
	height: 12px;
	border-radius: 50%;
	background: #60b246;
}

.magic-radio:checked + label:before {
	border: 2px solid #bdbdbd;
}

.magic-radio:checked[disabled] + label:before {
	border: 1px solid #c9e2f9;
}

.magic-radio:checked[disabled] + label:after {
	background: #c9e2f9;
}

.magic-checkbox + label:before {
	border-radius: 3px;
}

.magic-checkbox + label:after {
	top: 7px;
	right: -16%;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	width: 8px;
	height: 15px;
	-webkit-transform: rotate(45deg);
	-ms-transform: rotate(45deg);
	transform: rotate(45deg);
	border-width: 3px;
	border-style: solid;
	border-color: #fff;
	border-top: 0;
	border-left: 0;
}

.magic-checkbox:checked + label:before {
	border: #60b246;
	background: #60b246;
}

.magic-checkbox:checked[disabled] + label:before {
	border: #c9e2f9;
	background: #c9e2f9;
}
.form-group .addon-list {
	margin-bottom: 10px;
}
.addon-label {
	font-weight: 500;
	font-size: 1rem;
	text-transform: capitalize;
}
.addon-label-price {
	color: #93959f;
}
.addon-category-name {
	font-weight: 700;
	color: #424242;
	font-size: 1rem;
	margin-bottom: 1rem !important;
	display: block;
	border: 1px solid #ccc;
	padding: 5px 8px;
	width: fit-content;
}

#btn-customization-done {
	margin: 0;
	width: 110%;
	height: 4rem;
}
.customizable-item-text {
	text-align: left;
	font-weight: 400;
	font-size: 0.8rem;
	text-transform: lowercase;
	opacity: 0.8;
}
.noscroll {
	overflow: hidden;
}

.customizable-scroll {
	overflow-y: scroll;
}

button.payButton {
	width: 100%;
	height: 5.5rem;
	background-color: #fff;
	border: 0;
	-webkit-box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.05);
	box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.05);
	color: #282c3f !important;
	font-weight: 600;
	font-size: 1.1rem !important;
}

.facebook-login-button,
.google-login-button {
	height: 3rem;
	color: #000;
	background-color: #fff;
	font-weight: 500;
	padding: 0 12px;
	font-size: 1.1rem;
	border: 1px solid #eeeeee;
	border-radius: 4px;
}

.google-login-button:focus,
.facebook-login-button:focus {
	outline: none;
}
.login-or {
	color: #bec4d2;
}
.login-or:after,
.login-or:before {
	content: "\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0";
	text-decoration: line-through;
}

.srv-validation-message {
	font-size: 0.9rem;
	color: #ff5722;
	font-weight: 400;
	margin-top: 0.5rem;
}
.resend-otp {
	text-align: center;
	background-color: #fff;
	border: 1px solid #ccc;
	font-size: 1rem;
	color: #9e9e9e;
	width: 100%;
	font-weight: 500;
	padding: 10px 0;
}

.auth-input {
	height: 3.8rem;
	background-color: #f2f4f9;
	padding: 0 15px;
	border-radius: 4px;
	border: 0;
	margin-bottom: 10px;
}
.auth-input:focus {
	background-color: #f2f4f9;
	color: #000;
}

.countdown {
	font-size: 0.9rem;
	color: #9e9e9e;
	border: 0;
}

button.btn.btn-add-remove:disabled {
	opacity: 0.8;
	background-color: #eeeeee;
}

.quantity-badge-recommended {
	position: absolute;
	top: 5px;
	border-bottom-right-radius: 1rem;
	border: 2px solid #fff;
	border-top: 0;
	border-left: 0;
	height: 23px;
	width: 23px;
	font-size: 0.8rem;
	font-weight: 500;
	color: #fff;
	text-align: center;
}

.quantity-badge-list {
	position: absolute;
	border-bottom-right-radius: 1rem;
	border: 2px solid #fff;
	border-top: 0;
	border-left: 0;
	height: 20px;
	width: auto;
	min-width: 20px;
	font-size: 0.7rem;
	font-weight: 500;
	color: #fff;
	display: block;
	text-align: center;
	z-index: 1;
}
.quantity-badge-list--no-image {
	border-radius: 4px;
	border-top: 0;
	border-left: 0;
	height: 16px;
	line-height: 17px;
	width: auto;
	min-width: 20px;
	font-size: 10px;
	font-weight: 500;
	color: #fff;
	text-align: center;
	display: inline-block;
}
.popup-overlay.customizable-scroll {
	background: 0 !important;
}

.btn-preference {
	background-color: #fff;
	font-size: 0.8rem;
	height: 2rem;
	line-height: 8px;
	min-width: 82px;
	font-weight: 600;
	color: #616161;
}

.btn-preference-group {
	background-color: #fff;
	border: 1px solid #eee;
	border-radius: 25px;
}

.btn-group .btn-preference:first-child {
	border-radius: 25px 0 0 25px;
}

.btn-group .btn-preference:last-child {
	border-radius: 0 25px 25px 0;
	margin-left: -1px;
}

.user-preferred {
	color: #fff;
	font-weight: 600;
	border-radius: 25px !important;
	transition: 0.25s ease-in-out;
	transition-property: border-radius;
}

.sticky-top {
	position: -webkit-sticky;
	position: sticky;
	top: 0;
	z-index: 1;
}

.delivery-assigned-block {
	background-color: #fafafa;
	border-bottom: 1px solid #eee;
}

.ongoing-payment {
	z-index: 100;
}
.ongoing-payment-spin {
	background-color: rgba(96, 125, 139, 0.45);
}

.spin-load,
.spin-load:after {
	border-radius: 50%;
	width: 10em;
	height: 10em;
}
.spin-load {
	margin: 0px auto;
	font-size: 4px;
	border-top: 0.9em solid #424242;
	border-right: 0.9em solid #424242;
	border-bottom: 0.9em solid #424242;
	border-left: 0.9em solid transparent;
	-webkit-animation: load8 1s infinite linear;
	animation: load8 1s infinite linear;
	left: 44% !important;
	top: 45% !important;
}
@-webkit-keyframes load8 {
	0% {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}
	100% {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}
@keyframes load8 {
	0% {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}
	100% {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}

.location-error {
	bottom: 4.4rem !important;
	background-color: #222b45;
}

.delivery-header-button-container {
	z-index: 99;
	font-size: 2rem;
	margin-top: 10px;
	display: flex;
	gap: 5px;
}

.order-status-button {
	color: #424242;
	border: 1px solid #e0e0e0;
}

.order-track-button {
	border: 1px solid #e0e0e0;
	padding: 5px;
	font-size: 1rem;
	font-weight: 500;
	color: #60b246;
	line-height: 21.5px;
}

.pulse {
	display: inline-block;
	width: 12.5px;
	height: 12.5px;
	border-radius: 50%;
	-webkit-animation: pulse 2s infinite;
	animation: pulse 2s infinite;
	vertical-align: middle;
	margin: -1px 0 0 3px;
	background: #60b246;
}
@-webkit-keyframes pulse {
	0% {
		-webkit-box-shadow: 0 0 0 0 rgba(96, 178, 70, 0.5);
		box-shadow: 0 0 0 0 rgba(96, 178, 70, 0.5);
	}
	50% {
		-webkit-box-shadow: 0 0 0 26px rgba(96, 178, 70, 0);
		box-shadow: 0 0 0 26px rgba(96, 178, 70, 0);
	}
	100% {
		-webkit-box-shadow: 0 0 0 0 rgba(96, 178, 70, 0);
		box-shadow: 0 0 0 0 rgba(96, 178, 70, 0);
	}
}
@keyframes pulse {
	0% {
		-webkit-box-shadow: 0 0 0 0 rgba(96, 178, 70, 0.5);
		box-shadow: 0 0 0 0 rgba(96, 178, 70, 0.5);
	}
	50% {
		-webkit-box-shadow: 0 0 0 26px rgba(96, 178, 70, 0);
		box-shadow: 0 0 0 26px rgba(96, 178, 70, 0);
	}
	100% {
		-webkit-box-shadow: 0 0 0 0 rgba(96, 178, 70, 0);
		box-shadow: 0 0 0 0 rgba(96, 178, 70, 0);
	}
}

.ongoing-order-notify {
	background-color: #282c3f;
	border-radius: 4px;
	border-bottom-left-radius: 0;
	border-bottom-right-radius: 0;
	position: relative;
	z-index: 0;
}
.going-different-restaurant-notify {
	margin-bottom: 3.8rem;
	font-size: 0.9rem;
	font-weight: 400;
	z-index: 10;
}

.btn-outline-secondary:hover,
.btn-outline-secondary:focus,
.btn-outline-secondary.focus {
	color: #424242;
	background-color: #fff;
	border-color: #e0e0e0 !important;
}

.show-more {
	color: #60b246;
	cursor: pointer;
	font-size: 0.9rem;
}

.transaction-wrapper {
	padding: 1.5rem 1.5rem 1.5rem 0.5rem;
	border-radius: 0.275rem;
}
.transaction-wrapper:nth-child(even) {
	background-color: rgba(247, 247, 247, 0.5);
}
.payment-select-block {
	border-bottom: 2px solid #eee;
}

.btn-redeem {
	height: 3rem;
	border-radius: 0.175rem;
	background: transparent;
	border: 1px solid #ccc;
	width: 100%;
}

.no-click {
	pointer-events: none;
}
.yes-click {
	pointer-events: all;
}



.location-suggestions-container {
	position: relative;
	margin-top: 30px;
}
.location-suggestion {
	padding: 5px 15px;
	border-bottom: 1px solid #eee;
	cursor: pointer;
}
.location-main-name {
	color: #171717;
	font-weight: 600;
}
.location-secondary-name {
	color: #757575;
}
.input-location-icon-field i {
	position: absolute;
	right: 10px;
	font-size: 23px;
	color: #bdbdbd;
	top: 16px;
}

.btn-gps {
	margin-top: 2rem;
	color: #fff;
	width: 90%;
}

.location-loading-spinner {
	height: 40px;
	margin: 1rem auto auto;
	width: 40px;
	display: block;
}

.center-marker {
	position: absolute;
	top: 25vh;
	left: 48vw;
	width: 24px;
	height: auto;
}
.center-marker-pulse::after {
	position: absolute;
	content: "";
	top: 29.3%;
	left: 50.7%;
	height: 140px;
	width: 140px;
	-webkit-transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	-webkit-animation: marker-pulse 1.2s ease-out infinite;
	animation: marker-pulse 1.2s ease-out infinite;
	background: rgba(255, 152, 0, 0.2);
	border: 2px solid #ff9800;
	border-radius: 100%;
	pointer-events: none;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	will-change: transform;
}
@-webkit-keyframes marker-pulse {
	0% {
		-webkit-transform: translate(-50%, -50%) scale(0.1);
		transform: translate(-50%, -50%) scale(0.1);
		opacity: 1;
	}
	100% {
		-webkit-transform: translate(-50%, -50%) scale(0.8);
		transform: translate(-50%, -50%) scale(0.8);
		opacity: 0.3;
	}
}
@keyframes marker-pulse {
	0% {
		-webkit-transform: translate(-50%, -50%) scale(0.1);
		transform: translate(-50%, -50%) scale(0.1);
		opacity: 1;
	}
	100% {
		-webkit-transform: translate(-50%, -50%) scale(0.8);
		transform: translate(-50%, -50%) scale(0.8);
		opacity: 0.3;
	}
}
.confirm-gps-location {
	height: 44.7vh;
	bottom: 0;
	position: absolute;
	background-color: #fff;
	width: 100%;
	-webkit-box-shadow: 0px -3px 12px -2px #e0e0e0;
	box-shadow: 0px -3px 12px -2px #e0e0e0;
	-webkit-transition: 0.2s ease-out height;
	-o-transition: 0.2s ease-out height;
	transition: 0.2s ease-out height;
}

.bg-danger {
	background-color: #f44336 !important;
}
.text-danger {
	color: #f44336 !important;
}

.address-not-usable {
	color: #ccc;
}
.address-not-usable h6 {
	color: #ccc;
}
.text-sm09 {
	font-size: 0.9rem;
}
.text-sm08 {
	font-size: 0.8rem;
}

.language-select {
	border: 0;
	max-width: 130px;
	border-bottom: 1px solid #e0e0e0;
	border-radius: 0;
}

.react-tabs__tab-list {
	display: -webkit-box !important;
	display: -ms-flexbox !important;
	display: flex !important;
	-ms-flex-pack: distribute !important;
	justify-content: space-around !important;
	border: 0 !important;
	background-color: #161b31;
}

.react-tabs__tab {
	padding: 10px 14px !important;
	color: #e0e0e0;
}

.react-tabs__tab--selected {
	border-color: transparent !important;
	color: #18c775 !important;
	font-weight: 700;
	background: #222b45 !important;
	border-radius: 0 !important;
}

.react-tabs__tab:focus {
	-webkit-box-shadow: none !important;
	box-shadow: none !important;
	border-color: none !important;
}

.delivery-block-ongoing {
	background: linear-gradient(to right, #ff9801, #f44336);
	-webkit-transition: 0.2s linear all;
	-o-transition: 0.2s linear all;
	transition: 0.2s linear all;
}
.delivery-block-completed {
	background: #18c775;
	-webkit-transition: 0.2s linear all;
	-o-transition: 0.2s linear all;
	transition: 0.2s linear all;
}

.secondary-slider-wrapper {
	padding: 1rem 0;
	border-left: 0;
	border-right: 0;
	background-color: #fafafa;
}

.ribbon-box:after {
	content: "";
	-webkit-transform: translateX(100%);
	-ms-transform: translateX(100%);
	transform: translateX(100%);
	width: 45px;
	right: 0;
	height: 22px;
	position: absolute;
	z-index: 1;
	-webkit-animation: slide 2.2s infinite;
	animation: slide 2.2s infinite;
	background: -webkit-gradient(
			linear,
			left top,
			right top,
			from(rgba(255, 255, 255, 0)),
			color-stop(50%, rgba(255, 255, 255, 0.8)),
			color-stop(99%, rgba(128, 186, 232, 0)),
			to(rgba(125, 185, 232, 0))
	);
	background: linear-gradient(
			to right,
			rgba(255, 255, 255, 0) 0%,
			rgba(255, 255, 255, 0.8) 50%,
			rgba(128, 186, 232, 0) 99%,
			rgba(125, 185, 232, 0) 100%
	);
}

@-webkit-keyframes slide {
	0% {
		-webkit-transform: translateX(-130%);
		transform: translateX(-130%);
	}
	100% {
		-webkit-transform: translateX(100%);
		transform: translateX(100%);
	}
}

@keyframes slide {
	0% {
		-webkit-transform: translateX(-130%);
		transform: translateX(-130%);
	}
	100% {
		-webkit-transform: translateX(100%);
		transform: translateX(100%);
	}
}

.items-search-box {
	border-right: 0;
	background-color: #fcfcfc;
	height: 3.8rem;
	border-top-left-radius: 0.775rem !important;
	border-bottom-left-radius: 0.775rem !important;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-shadow: 0px 4px 10px -10px #ccc;
	box-shadow: 0px 4px 10px -10px #ccc;
	border-radius: 0;
}
.items-search-box:focus {
	border: 1px solid #eee;
	border-right: 0;
}

.search-shadow-light {
	-webkit-box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.05);
	box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.05);
	-webkit-transition: 0.2s linear all;
	-o-transition: 0.2s linear all;
	transition: 0.2s linear all;
	border-top-left-radius: 0.775rem;
	border-bottom-left-radius: 0.775rem;
}
.items-search-box-icon {
	border-left: 0;
	background: #fcfcfc;
	background-color: #fcfcfc;
	height: 3.8rem;
	border-radius: 0.775rem;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-shadow: 0px 4px 10px -10px #ccc;
	box-shadow: 0px 4px 10px -10px #ccc;
}

.input-group {
	-webkit-transition: 0.2s linear all;
	-o-transition: 0.2s linear all;
	transition: 0.2s linear all;
}

.border-radius-275 {
	border-radius: 0.275rem !important;
}

.update-full-notification {
	position: fixed;
	background-color: rgba(96, 125, 139, 0.6);
	width: 100%;
	text-align: center;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	height: 100.2vh;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	vertical-align: middle;
	-ms-flex-pack: distribute;
	justify-content: space-around;
	border-radius: 0.275rem;
	bottom: 0;
	z-index: 9999999999;
}

.update-full-notification > h1 {
	color: #e0e0e0;
	text-transform: capitalize;
	font-size: 1.5rem;
	word-spacing: 0.2rem;
	letter-spacing: 0.05rem;
}

.store-logo {
	width: 100%;
	max-width: 110px;
}

.certificate-code {
	font-size: 1rem;
	color: #bdbdbd;
	font-weight: 700;
}

.explore-bg {
	width: 80%;
	opacity: 0.5;
	height: intrinsic;
}

.menu-button-block-main {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	position: fixed;
	bottom: 5rem;
	left: 50%;
	-webkit-transform: translateX(-50%);
	-ms-transform: translateX(-50%);
	transform: translateX(-50%);
	z-index: 9;
	-webkit-transition: linear 0.1s bottom;
	-o-transition: linear 0.1s bottom;
	transition: linear 0.1s bottom;
}
.btn-menu-list {
	border-radius: 30px;
	height: 2.6rem;
	min-width: 90px;
	color: #fff;
	font-size: 1.1rem;
	-webkit-box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.3), 0 2px 1px 0 rgba(93, 141, 213, 0.2);
	box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.3), 0 2px 1px 0 rgba(93, 141, 213, 0.2);
	z-index: 9;
	font-weight: 600;
	font-size: 0.95rem;
	background-color: #5d8ed5 !important;
}
.menu-items-block {
	position: fixed;
	left: 50%;
	bottom: 100px;
	-webkit-transform: translateX(-50%);
	-ms-transform: translateX(-50%);
	transform: translateX(-50%);
	pointer-events: auto;
	z-index: 102499999;
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
	will-change: transform;
}
.menu-item-block-inner {
	padding: 32px;
	max-height: 320px;
	width: 250px;
	overflow: auto;
	background: #fff;
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
	-webkit-transform: translateZ(0);
	transform: translateZ(0);
	will-change: transform, opacity;
	-webkit-transform-origin: bottom;
	-ms-transform-origin: bottom;
	transform-origin: bottom;
	opacity: 1;
	border-radius: 0.275rem;
}
.menu-item-block-single {
	margin-bottom: 20px;
	font-size: 1.15rem;
	color: #3d4152;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	overflow: hidden;
}
.menu-item-block-single-name {
	/*-o-text-overflow: ellipsis;*/
	/*text-overflow: ellipsis;*/
	/*overflow: hidden;*/
	white-space: break-spaces;
	text-transform: capitalize;
	-webkit-box-flex: 1;
	-ms-flex: 1;
	flex: 1;
}

.menu-item-block-single-quantity {
	margin-left: 16px;
}
.menu-open-backdrop {
	z-index: 102499998;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: rgba(40, 44, 62, 0.6);
}

.page-inactive {
	-webkit-filter: grayscale(1);
	filter: grayscale(1);
	pointer-events: none;
}

button[type="submit"]:disabled {
	opacity: 0.5;
}

.category-slider-name {
	position: absolute;
	-webkit-transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	color: white;
	background: rgba(0, 0, 0, 0.8);
	border-radius: 3px;
	font-weight: 500;
	font-size: 0.7rem;
	left: 50%;
	top: 100%;
	text-align: center;
	padding: 0 5px;
}

.category-checkboxes-block {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	overflow-x: auto;
}

.category-checkboxes-block label {
	margin-right: 10px;
	background-color: rgba(250, 250, 250, 0.3);
	border-radius: 25px;
}

.category-checkboxes-block label span {
	text-align: center;
	display: block;
	padding: 5px 10px;
	border: 1px solid #eee;
	border-radius: 25px;
	min-width: -webkit-max-content;
	min-width: -moz-max-content;
	min-width: max-content;
	width: -webkit-max-content;
	width: -moz-max-content;
	width: max-content;
}

.category-checkboxes-block label input {
	position: absolute;
	top: -20px;
}

.category-checkboxes-block input:checked + span {
	background-color: rgb(96, 178, 70);
	padding: 5px 10px;
	color: #fff;
	border: 1px solid #eee;
	font-weight: 500;
	width: -webkit-max-content;
	width: -moz-max-content;
	width: max-content;
}

.filter-count-block {
	margin-right: 10px;
	background-color: rgba(250, 250, 250, 0.3);
	border-radius: 25px;
	min-width: 85px;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	font-weight: 700;
	border: 1px solid rgb(252, 128, 25);
	margin-bottom: 5px;
	color: rgb(252, 128, 25);
}

.filter-no-found {
	color: #e0e0e0;
	letter-spacing: 1px;
}
.dv-star-rating {
	font-size: 2.6rem;
}
/* .dv-star-rating i {
    filter: drop-shadow(0px 3px 2px #eee);
} */

.dv-star-rating-empty-star {
	color: #e0e0e0 !important;
}
.review-icon {
	width: 6rem;
	margin-right: 1rem;
}

.feedback-textarea {
	width: 100%;
	border: 1px solid #e0e0e0;
	height: 60px;
	resize: none;
	position: relative;
	opacity: 0.9;
	background-color: #fff;
	border-radius: 0.275rem;
	padding: 0.5rem;
	-webkit-transition: 0.2s linear all;
	-o-transition: 0.2s linear all;
	transition: 0.2s linear all;
}
.feedback-textarea:focus {
	-webkit-box-shadow: 0 3px 5px 1px #eeeeee;
	box-shadow: 0 3px 5px 1px #eeeeee;
	outline: 0;
	height: 120px;
	padding: 1rem;
	-webkit-transition: 0.2s linear all;
	-o-transition: 0.2s linear all;
	transition: 0.2s linear all;
}
.btn-fixed-bottom {
	position: fixed;
	bottom: 0;
	width: 100%;
	margin: 0 auto;
	padding: 0.9375rem 0;
	line-height: 2.15rem;
	font-size: 1.2rem;
	font-weight: 600;
	display: block;
	text-transform: uppercase;
	border-radius: 0;
	color: #fff;
	text-align: center;
	border: 0;
	left: 0;
}

@-webkit-keyframes strike-text {
	0% {
		width: 0;
	}
	100% {
		width: 100%;
	}
}

@keyframes strike-text {
	0% {
		width: 0;
	}
	100% {
		width: 100%;
	}
}
.strike-text {
	position: relative;
}
.strike-text::after {
	content: " ";
	position: absolute;
	top: 0.7em;
	left: 0;
	width: 100%;
	height: 1px;
	background: #282c3f;
	-webkit-animation-name: strike-text;
	animation-name: strike-text;
	-webkit-animation-duration: 1s;
	animation-duration: 1s;
	-webkit-animation-timing-function: linear;
	animation-timing-function: linear;
	-webkit-animation-iteration-count: 1;
	animation-iteration-count: 1;
	-webkit-animation-fill-mode: forwards;
	animation-fill-mode: forwards;
}

.price-percentage-discount {
	font-weight: 700;
	font-size: 0.8rem;
}

.location-saved-address {
	background-color: #fafafa;
	min-height: 80vh;
}

.alert-notify-bell {
	position: absolute;
	right: 16px;
	bottom: 6px;
	color: #ccc;
	-webkit-animation: alert-notify-bell 8s 1s ease-in-out infinite;
	animation: alert-notify-bell 8s 1s ease-in-out infinite;
	-webkit-transform-origin: 50% 4px;
	-ms-transform-origin: 50% 4px;
	transform-origin: 50% 4px;
}

.has-alert {
	position: relative;
	-webkit-animation: alert-notify-bell 8s 1s ease-in-out infinite;
	animation: alert-notify-bell 8s 1s ease-in-out infinite;
	-webkit-transform-origin: 50% 4px;
	-ms-transform-origin: 50% 4px;
	transform-origin: 50% 4px;
}

@-webkit-keyframes alert-notify-bell {
	0% {
		-webkit-transform: rotateZ(0);
	}
	1% {
		-webkit-transform: rotateZ(30deg);
	}
	3% {
		-webkit-transform: rotateZ(-28deg);
	}
	5% {
		-webkit-transform: rotateZ(34deg);
	}
	7% {
		-webkit-transform: rotateZ(-32deg);
	}
	9% {
		-webkit-transform: rotateZ(30deg);
	}
	11% {
		-webkit-transform: rotateZ(-28deg);
	}
	13% {
		-webkit-transform: rotateZ(26deg);
	}
	15% {
		-webkit-transform: rotateZ(-24deg);
	}
	17% {
		-webkit-transform: rotateZ(22deg);
	}
	19% {
		-webkit-transform: rotateZ(-20deg);
	}
	21% {
		-webkit-transform: rotateZ(18deg);
	}
	23% {
		-webkit-transform: rotateZ(-16deg);
	}
	25% {
		-webkit-transform: rotateZ(14deg);
	}
	27% {
		-webkit-transform: rotateZ(-12deg);
	}
	29% {
		-webkit-transform: rotateZ(10deg);
	}
	31% {
		-webkit-transform: rotateZ(-8deg);
	}
	33% {
		-webkit-transform: rotateZ(6deg);
	}
	35% {
		-webkit-transform: rotateZ(-4deg);
	}
	37% {
		-webkit-transform: rotateZ(2deg);
	}
	39% {
		-webkit-transform: rotateZ(-1deg);
	}
	41% {
		-webkit-transform: rotateZ(1deg);
	}

	43% {
		-webkit-transform: rotateZ(0);
	}
	100% {
		-webkit-transform: rotateZ(0);
	}
}

@keyframes alert-notify-bell {
	0% {
		-webkit-transform: rotateZ(0);
	}
	1% {
		-webkit-transform: rotateZ(30deg);
	}
	3% {
		-webkit-transform: rotateZ(-28deg);
	}
	5% {
		-webkit-transform: rotateZ(34deg);
	}
	7% {
		-webkit-transform: rotateZ(-32deg);
	}
	9% {
		-webkit-transform: rotateZ(30deg);
	}
	11% {
		-webkit-transform: rotateZ(-28deg);
	}
	13% {
		-webkit-transform: rotateZ(26deg);
	}
	15% {
		-webkit-transform: rotateZ(-24deg);
	}
	17% {
		-webkit-transform: rotateZ(22deg);
	}
	19% {
		-webkit-transform: rotateZ(-20deg);
	}
	21% {
		-webkit-transform: rotateZ(18deg);
	}
	23% {
		-webkit-transform: rotateZ(-16deg);
	}
	25% {
		-webkit-transform: rotateZ(14deg);
	}
	27% {
		-webkit-transform: rotateZ(-12deg);
	}
	29% {
		-webkit-transform: rotateZ(10deg);
	}
	31% {
		-webkit-transform: rotateZ(-8deg);
	}
	33% {
		-webkit-transform: rotateZ(6deg);
	}
	35% {
		-webkit-transform: rotateZ(-4deg);
	}
	37% {
		-webkit-transform: rotateZ(2deg);
	}
	39% {
		-webkit-transform: rotateZ(-1deg);
	}
	41% {
		-webkit-transform: rotateZ(1deg);
	}

	43% {
		-webkit-transform: rotateZ(0);
	}
	100% {
		-webkit-transform: rotateZ(0);
	}
}

.btn-clear-alerts {
	border: 1px solid #ef5350;
	background: transparent;
	color: #ef5350;
	border-radius: 0;
}

.styles_overlay__CLSq- {
	display: block !important;
	background-color: #fff !important;
}
.styles_modal__gNwvD {
	-webkit-box-shadow: none !important;
	box-shadow: none !important;
}

.styles_closeButton__20ID4:focus {
	outline: none !important;
}

.restaurant-not-active {
	-webkit-filter: grayscale(1);
	filter: grayscale(1);
}
.restaurant-not-active-msg {
	color: #ff7e00;
}

.product-slider {
	overflow-x: scroll;
	overflow-y: hidden;
	white-space: nowrap;
	scroll-behavior: smooth;
	-webkit-overflow-scrolling: touch;
}
.product-slider-item {
	display: inline-block;
	margin-left: 0.5rem;
	margin-right: 0.5rem;
}

.product-slider::-webkit-scrollbar {
	display: none;
}
.content-center {
	position: absolute;
	top: 50%;
	left: 50%;
	-webkit-transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
}

.recommended-v2-ellipsis-meta {
	max-width: 169px;
	display: inline-block;
	overflow: hidden;
	white-space: nowrap;
	-o-text-overflow: ellipsis;
	text-overflow: ellipsis;
	margin-bottom: -0.4rem;
}

.single-item-img {
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
}

.position-relative {
	position: relative;
}

.switch-wrapper {
	position: relative;
}

.switch-wrapper > div {
	position: absolute;
}
.single-address-card {
	padding: 10px;
	margin-bottom: 15px;
	border-radius: 4px;
	border: 1px solid #f4f4f4;
	cursor: pointer;
}

.location-back-button {
	position: relative;
	border-bottom: 1px solid #eee;
	border-radius: 0.1rem;
	background-color: #fafafa;
}
.location-back-button > i {
	font-size: 16px;
	margin-left: 15px;
}

.inAppNotificationActions {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	float: right;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
}

.inANClose {
	border-radius: 0.275rem;
	color: #4a4a4a;
	background-color: #eeeeee;
	border: 1px solid #fafafa;
}

.inANLink {
	border-radius: 0.275rem;
	background-color: rgb(255, 126, 0);
	border: 1px solid #fafafa;
	color: #fff !important;
}

.offline-mode-img {
	width: 50%;
	-webkit-animation: shake 0.85s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
	animation: shake 0.85s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
}

.StripeElement,
.StripeElementIdeal,
.StripeElementFpx {
	display: block;
	margin: 0px 0 12px 0;
	max-width: 500px;
	padding: 17px 14px;
	-webkit-box-shadow: rgba(50, 50, 93, 0.14902) 0px 1px 3px, rgba(0, 0, 0, 0.0196078) 0px 1px 0px;
	box-shadow: rgba(50, 50, 93, 0.14902) 0px 1px 3px, rgba(0, 0, 0, 0.0196078) 0px 1px 0px;
	border-radius: 4px;
	background: #fcfcfc;
}

.StripeElement--focus,
.StripeElementIdeal--focus,
.StripeElementFpx--focus {
	-webkit-box-shadow: rgba(50, 50, 93, 0.109804) 0px 4px 6px, rgba(0, 0, 0, 0.0784314) 0px 1px 3px;
	box-shadow: rgba(50, 50, 93, 0.109804) 0px 4px 6px, rgba(0, 0, 0, 0.0784314) 0px 1px 3px;
	-webkit-transition: all 150ms ease;
	-o-transition: all 150ms ease;
	transition: all 150ms ease;
}

.StripeElement.loading {
	height: 41.6px;
	opacity: 0.6;
}
.StripeElementIdeal {
	padding: 10px 14px;
	font-size: 18px;
	color: #424770;
	letter-spacing: 0.025em;
}
.StripeElementIdeal,
.StripeElementFpx {
	padding: 0;
}

.stripe-pay-btn {
	white-space: nowrap;
	border: 0;
	outline: 0;
	display: inline-block;
	height: 3.8rem;
	line-height: 40px;
	padding: 0 14px;
	-webkit-box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
	box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
	color: #fff;
	border-radius: 4px;
	font-size: 16px;
	font-weight: 600;
	text-transform: uppercase;
	letter-spacing: 0.025em;
	background-color: #6772e5;
	text-decoration: none;
	-webkit-transition: all 150ms ease;
	-o-transition: all 150ms ease;
	transition: all 150ms ease;
	margin-top: 10px;
	width: 100%;
}
.stripe-toggle-active {
	-webkit-box-shadow: 0px -8px 12px 3px #e6e6e6;
	box-shadow: 0px -8px 12px 3px #e6e6e6;
	z-index: 1;
}
.stripe-card-container {
	margin-top: -1.9rem;
	padding: 20px 10px 10px 10px;
	margin-bottom: 2rem;
	border: 1px solid #dfdfdf;
	border-top: 0;
	background-color: #fff;
	-webkit-box-shadow: 0px 8px 10px 2px #e6e6e6;
	box-shadow: 0px 8px 10px 2px #e6e6e6;
	margin: -1.9rem 7px 2rem 7px;
}

.stripe-saved-card-divider {
	overflow: hidden;
	text-align: center;
	margin-top: 10px;
}
.stripe-saved-card-divider:before,
.stripe-saved-card-divider:after {
	background-color: #000;
	content: "";
	display: inline-block;
	height: 1px;
	position: relative;
	vertical-align: middle;
	width: 50%;
}
.stripe-saved-card-divider:before {
	right: 0.5em;
	margin-left: -50%;
}

.stripe-saved-card-divider:after {
	left: 0.5em;
	margin-right: -50%;
}

label[for="savePaymentMethod"] {
	vertical-align: middle;
	margin-bottom: 0;
}
input#savePaymentMethod {
	display:inline;
	margin-right: 10px;
	vertical-align: middle;
	margin-bottom: 0;
	width:25px;
}

.SelectFieldNativeSelect {
	color: #ccc !important;
}

.mock-search-block {
	background-color: #f8f9fa;
}

.mock-search-block > div {
	background-color: #fff;
	height: 3.8rem;
	border-radius: 0.775rem;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-shadow: 0px 4px 10px -10px #ccc;
	box-shadow: 0px 4px 10px -10px #ccc;
}

.mock-search-block span {
	font-size: 1.1rem;
	font-weight: 500;
	color: #ababab;
	letter-spacing: -0.1px;
}

.mock-search-block i {
	color: #ababab;
	font-weight: 700;
}
.nav-home-btn > i {
	font-weight: 100;
	font-size: 1.4rem;
	opacity: 0.8;
	color: #424242;
}
.nav-share-btn > i {
	font-weight: 100;
	font-size: 1.4rem;
	opacity: 0.8;
	color: #424242;
}

.billdetail-paymentmode {
	font-size: 12px;
	font-weight: 500;
	border-radius: 0.275rem;
}

/*.bg-grey-light.mt-20.mb-100 div,*/
/*.Collapsible,*/
/*.Collapsible__trigger,*/
/*.Collapsible__contentOuter,*/
/*.category-list-item,*/
/*.category-list-item a,*/
/*.category-list-item a > img {*/
/*	-webkit-transform: translate3d(0, 0, 0);*/
/*}*/

.coupon-block-not-loggedin {
	filter: blur(3px) grayscale(3);
	pointer-events: none;
}
.coupon-not-loggedin-message {
	background-color: #fafafa;
	border-bottom-left-radius: 1.5rem;
	border-bottom-right-radius: 1.5rem;
}

/*Swipe Button CSS*/
.ReactSwipeButton {
	float: left;
	width: 100%;
	height: 58px;
	position: relative;
}
.ReactSwipeButton,
.ReactSwipeButton * {
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}
.rsbContainer {
	float: left;
	width: 100%;
	height: 100%;
	background: #eee;
	position: relative;
	overflow: hidden;
	box-shadow: inset 2px 2px 4px rgba(0, 0, 0, 0.1);
}
.rsbContainerUnlocked {
	display: none;
}
.rsbcSlider {
	float: left;
	width: 100%;
	position: absolute;
	height: 58px;
	top: 0;
	left: 80px;
	margin-left: -100%;
	background: #0d101d;
	z-index: 100;
	box-shadow: 2px 1px 6px 2px rgba(38, 50, 56, 0.35);
	cursor: pointer;
}
.rsbcSliderText {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	line-height: 58px;
	text-align: center;
	color: #fff;
	font-size: 16px;
	font-weight: 500;
}
.rsbcSliderArrow {
	float: left;
	position: absolute;
	transform: translateX(-4px) rotate(45deg);
	border: 3px solid #fff;
	height: 13px;
	width: 13px;
	top: 50%;
	right: 22px;
	margin-top: -6px;
	border-left-color: transparent;
	border-bottom-color: transparent;
	transform-origin: center;
	z-index: 10;
	animation: rsbcSliderArrowAnimate 2s linear infinite;
}
@keyframes rsbcSliderArrowAnimate {
	0% {
		transform: translateX(-4px) rotate(45deg);
	}
	50% {
		transform: translateX(6px) rotate(45deg);
	}
	100% {
		transform: translateX(-4px) rotate(45deg);
	}
}
.rsbcSliderCircle {
	position: absolute;
	right: 0;
	background: #263238;
	top: 0;
	height: 58px;
	width: 50px;
	border-radius: 100%;
}
.rsbcText {
	float: left;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	height: 58px;
	line-height: 58px;
	text-align: center;
	color: #fff;
	font-size: 16px;
	font-weight: 500;
}
.slider-bg-color {
	background-color: #18c775;
}
/* END Swipe Button CSS*/

.delivery-error {
	position: fixed;
	bottom: 5.5rem;
	text-align: center;
	width: 100%;
	background-color: #f44336;
	color: #f4f4f5;
	padding: 0.8rem;
	z-index: 2147483647;
}

.auth-header {
	background-color: #f2f4f9;
}

.cust-auth-header {
	background-color: #f2f4f9;
}

.bg-del-timeline-icon {
	background-color: #fff;
	color: #000 !important;
	font-weight: 500;
	font-size: 1.4rem;
}
.nav-dark {
	background-color: #222b45;
	color: #fff;
}
.delivery-dark-bg {
	background-color: #161b31;
}

.delivery-address-timeline::before {
	position: absolute;
	top: 0;
	left: 24px;
	bottom: 0;
	display: block;
	width: 2px;
	content: "";
	z-index: 1;
	background-image: linear-gradient(#e0e0e0 33%, rgba(255, 255, 255, 0) 0%);
	background-position: right;
	background-size: 1px 10px;
	background-repeat: repeat-y;
	background-color: #222b45;
}
.delivery-address-timeline {
	background-color: #222b45;
	color: #fff;
	border-bottom-left-radius: 8px;
	border-bottom-right-radius: 8px;
}

.delivery-address-timeline .btn-get-direction {
	background-color: #8360c3;
	border-radius: 4px;
	color: #fff;
	border: 0;
	height: 2.2rem;
	line-height: 3px;
}
.single-order-metas {
	background-color: #192038;
	color: #fff;
	padding: 20px;
	border-top-left-radius: 8px;
	border-top-right-radius: 8px;
}
.single-order-commission-block {
	position: absolute;
	top: 6rem;
	right: 2rem;
	z-index: 8;
	color: #18c775;
	font-weight: 600;
	font-size: 30px;
}
.single-order-restaurant-description {
	display: none;
}

.single-order-items-title {
	background-color: #192038;
	color: #fff;
	padding: 20px;
	border-top-left-radius: 8px;
	border-top-right-radius: 8px;
	font-weight: 600;
	font-size: 1.2rem;
}

.single-order-items-list {
	background-color: #222b45;
	color: #fff;
	border-bottom-left-radius: 8px;
	border-bottom-right-radius: 8px;
}

.single-item-division-hr {
	border: 0.5px dashed #515875;
}

.delivery-order-item-quantity {
	border: 1px solid #e0e0e0;
	padding: 2px 8px;
	font-weight: 500;
	min-width: 35px;
	text-align: center;
	border-radius: 6px;
}
.delivery-items-addons-block {
	margin-left: 55px;
	color: #979797;
}
.delivery-pin-error {
	text-align: center;
	width: 100%;
	background-color: #f44336;
	color: #f4f4f5;
	padding: 0.8rem;
	border-radius: 20px;
	margin-top: 10px;
}

.btn-refreshOrders {
	height: 2.2rem;
	line-height: 3px;
}

.btn-refreshOrders-text {
	display: flex;
	align-items: center;
	gap: 5px;
}

.delivery-tab-title {
	font-size: 1.4rem;
	font-weight: 700;
	height: 4rem;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
}

.list-delivery-commission {
	color: #18c775;
	font-weight: 700;
	font-size: 1rem;
}

.list-delivery-store-name {
	color: #60c37d;
	font-size: 1.3rem;
}

.delivery-list-wrapper {
	list-style: none;
	padding-left: 0;
}

.delivery-list-wrapper .delivery-list-item {
	margin-bottom: 20px;
	background-color: #222b45;
	color: #fff;
	border-radius: 8px;
}
.btn-delivery-logout {
	background-color: #8360c3;
	color: #fff;
}

.delivery-account-orders-block {
	background-color: #222b45;
	color: #fafafa;
}
.btn-delivery-success,
.delivery-wallet-deposit {
	color: #fff;
	background-color: #18c775;
	border-radius: 4px;
}
.btn-delivery-ongoing,
.delivery-wallet-withdraw {
	color: #fff;
	background: linear-gradient(to right, #ff9801, #f44336);
	border-radius: 4px;
}

.delivery-block-transparent {
	background-color: rgba(0, 0, 0, 0.25);
	color: #fff;
}

.delivery-login-title {
	color: #fafafa;
	font-size: 1.4rem;
	font-weight: 700;
}
.delivery-login-subtitle {
	color: #eee;
	font-size: 1.2rem;
	font-weight: 400;
}
.delivery-login-input {
	margin-bottom: 1.5rem;
	border: 0;
	border-radius: 0;
	padding: 0;
	height: 3.8rem;
	background-color: rgba(0, 0, 0, 0.4);
	border-radius: 4px;
	color: #fafafa;
	padding-left: 10px;
	transition: 0.2s linear all;
}
.delivery-login-input:focus {
	color: #fafafa;
	background-color: rgba(0, 0, 0, 0.6) !important;
	transition: 0.2s linear all;
}
.delivery-language-select {
	border: 0;
	max-width: 130px;
	border-radius: 4px;
	background-color: rgba(0, 0, 0, 0.4);
	color: #fafafa;
	transition: 0.2s linear all;
}
.delivery-language-select:focus {
	background-color: rgba(0, 0, 0, 0.6);
	color: #fafafa;
	transition: 0.2s linear all;
}

.delivery-login-button {
	background-color: #18c775 !important;
	color: #fafafa;
	width: 100%;
	height: 4rem;
	font-size: 1.2rem;
	border-radius: 4px;
	font-weight: 700;
	text-transform: uppercase;
}
.delivery-bike-img {
	height: 80px;
}

.delivery-login-logo {
	display: none;
}

.delivery-already-accepted-error {
	bottom: 0;
}
.delivery-max-order-reached {
	bottom: 4.3rem;
}
.single-order-earnings-block {
	background-color: #222b45;
	color: #fff;
	border-radius: 8px;
}
.single-order-total-earnings {
	color: #18c775;
	font-size: 1.5rem;
	font-weight: 700;
}

.btn-toggleLightDark {
	border-radius: 0;
	background-color: black;
	color: #fff;
	height: 45px;
}

/* Tip System */

.tips-block {
	border-radius: 4px;
}
.tip-switch-field {
	display: flex;
	overflow-x: scroll;
}

.tip-switch-field input {
	position: absolute !important;
	clip: rect(0, 0, 0, 0);
	height: 1px;
	width: 1px;
	border: 0;
	overflow: hidden;
}

.tip-switch-field label {
	border-radius: 4px;
	color: #282c3f;
	font-size: 14px;
	line-height: 1;
	text-align: center;
	padding: 6px 10px;
	border: 1px solid #e0e0e0;
	transition: all 0.1s ease-in-out;
	white-space: nowrap;
}

.tip-switch-field label:hover {
	cursor: pointer;
}

.tip-switch-field input:checked + label {
	color: white;
	background-color: #ff7400;
	border-radius: 4px;
	border: 0;
	box-shadow: 0px 4px 4px -2px #cdcdcd;
}

.tip-switch-field .tips {
	padding-right: 2px;
}

.custom-tip-input {
	border: 1px solid #e0e0e0;
	height: 3rem;
}

/* END Tip System */

/* Try on Phone */
.use-on-phone {
	position: fixed;
	bottom: 20px;
	left: 20px;
	display: flex;
	padding: 10px 20px;
	color: #000;
	box-shadow: 0 3px 6px -1px rgba(0, 0, 0, 0.12), 0 6px 25px -4px rgba(137, 137, 137, 0.3);
	background: #fff;
	border-radius: 0.275rem;
	max-width: 90% !important;
	align-items: center;
	z-index: 2147483647;
}
.float-phone {
	width: 30px;
	transform-style: preserve-3d;
	animation-name: floating;
	animation-duration: 3s;
	animation-iteration-count: infinite;
	animation-timing-function: ease-in-out;
}
@keyframes floating {
	from {
		transform: translate(0, 0);
	}
	50% {
		transform: translate(0, 5px);
	}
	to {
		transform: translate(0, 0);
	}
}
@media (min-width: 320px) and (max-width: 767px) {
	.use-on-phone {
		display: none !important;
	}
}
/* END Try on Phone */
.country-code {
	position: absolute;
	color: #575757;
	border-right: 1px solid #e2e4e9;
	padding: 0 10px;
	z-index: 9;
	line-height: 3.8rem;
}
.country-code--dropdown {
	position: absolute;
	color: #575757;
	border: 0;
	padding: 1px;
	z-index: 9;
	height: 3.8rem;
	border-bottom: 1.2px solid #eee;
	background-color: #f2f4f9;
	border-radius: 4px;
	width: 60px;
}

.phone-number {
	margin-bottom: 1.5rem;
	border: 0;
	border-bottom: 1.2px solid #eee;
	border-radius: 0;
	padding-left: 35px;
}

.phone-number-country-code {
	border-bottom: 1.2px solid #eee;
	padding-left: 60px;
}

.fav-restaurant {
	fill: red;
	width: 20px;
	/* animation: pulse 1s ease infinite; */
	position: absolute;
	right: 25px;
	top: 70px;
}

.not-fav-restaurant {
	fill: grey;
	width: 20px;
	/* animation: pulse 1s ease infinite; */
	position: absolute;
	right: 25px;
	top: 70px;
}

@keyframes move-top-to-bottom {
	0% {
		transform: scale(1);
	}
	50% {
		transform: scale(1.3);
	}
	100% {
		transform: scale(1);
	}
}

.clear-cart-btn {
	height: 3rem;
	min-width: 125px;
	box-shadow: none;
	text-transform: uppercase;
	text-align: center;
	border: 2px solid;
	background-color: #fff;
}

.heart {
	position: absolute;
	width: 100px;
	height: 100px;
	right: -18px;
	top: 2rem;
	background: url(/assets/img/various/heart.png) no-repeat;
	background-position: 0 0;
	cursor: pointer;
	transition: background-position 1s steps(28);
	transition-duration: 0s;
	transform: scale(0.65);
}
.heart.is-active {
	transition-duration: 1s;
	background-position: -2800px 0;
}

.cart-delivery-type-img {
	border: 1px solid #e0e0e0;
	padding: 15px;
	border-radius: 4px;
	min-width: 100px;
	text-align: center;
}
.cart-delivery-type-img img {
	width: 50px;
}

.flutterwave-btn {
	position: absolute;
	top: -9999px;
	left: -9999px;
}

.gps-blocked-error {
	position: fixed;
	bottom: 0;
	text-align: center;
	width: 100%;
	background-color: #282c3f;
	color: #f4f4f5;
	padding: 0.8rem;
	z-index: 2147483647;
}

.veg-non-veg-badge {
	width: 0.85rem;
	object-fit: contain;
	/*position: absolute;*/
	/*right: 0;*/
	/*top: 2px;*/
}
.veg-non-veg-badge-noimage {
	width: 0.85rem;
}

.flex-item-name .item-name {
	font-weight: 600;
	font-size: 1rem;
}
.item-badge-collection {
	display: block;
}

.flex-item-name .item-price {
	line-height: 2rem;
}

.ml-12 {
	margin-left: 12px !important;
}

.account-pages {
	display: flex;
	overflow: scroll;
	flex-wrap: wrap;
}

.pages-badge {
	background-color: #f8f9fa;
	padding: 8px 10px;
	border: 1px solid #f4f4f4;
	border-radius: 4px;
	min-width: max-content;
	font-size: 0.9rem;
	font-weight: 500;
}

.my-account-menu-item-block {
	background-color: #f8f9fa;
	border: 1px solid #f4f4f4;
	min-width: 8rem;
	max-width: 8rem;
	min-height: 6rem;
}
.my-account-menu-item {
	padding: 15px 0 8px 0;
	margin-bottom: 0.3rem;
	border-radius: 4px;
}

.my-account-menu-item i {
	font-size: 1.2rem;
	color: #ffffff;
	padding: 0.5rem;
	border-radius: 50%;
	text-align: center;
}
.my-account-menu-item i.si-home {
	background-color: #3498db;
}
.my-account-menu-item i.si-bag {
	background-color: #9b59b6;
}
.my-account-menu-item i.si-wallet {
	background-color: #4caf50;
}
.my-account-menu-item i.si-heart {
	background-color: #e91e63;
}
.my-account-menu-item i.si-tag {
	background-color: #607d8b;
}

.my-account-menu-item-block .text-center {
	font-size: 0.9rem;
	font-weight: 500;
}

.account-menu {
	display: flex;
	overflow: scroll;
	flex-wrap: wrap;
}
.account-menu .my-account-menu-item-block {
	margin-bottom: 15px;
}

.account-logout {
	background-color: #f8f9fa;
	border: 1px solid #f4f4f4;
	text-align: center;
	padding: 10px;
	border-radius: 8px;
}

.wave-container {
	width: 100vw;
	position: fixed;
}

.wave-svg {
	width: 100%;
	overflow: visible;
}

.wave {
	animation: wave 3s linear;
	animation-iteration-count: infinite;
	fill: #e4e7ee;
}

#wave2 {
	animation-duration: 5s;
	animation-direction: reverse;
	opacity: 0.6;
}
#wave3 {
	animation-duration: 7s;
	opacity: 0.3;
}

@keyframes wave {
	to {
		transform: translateX(-100%);
	}
}

.login-bottom-wave {
	bottom: -26px;
	width: 100vw;
}

.fixed-pwa-install {
	position: fixed;
	bottom: 5rem;
	padding: 10px 25px;
	background-color: #f0f0f0;
	height: 5rem;
	justify-content: space-around;
	text-align: left;
}

.pwa-phone-icon {
	font-size: 1.6rem;
	padding: 0 5px;
}

.store-rating {
	color: #fff;
	padding: 2px 7px;
	border-radius: 4px;
	font-size: 10px;
	font-weight: 500;
}
.single-review {
	padding: 10px;
	margin-bottom: 15px;
	border-radius: 4px;
	border: 1px solid #f4f4f4;
	background-color: #fff;
}
.rating-green {
	background-color: #3cb854;
}
.rating-orange {
	background-color: #e6b601;
}
.rating-red {
	background-color: #ee5159;
}

.cash-change-input {
	height: 4rem;
	font-size: 15px;
	border-radius: 0;
	border-bottom: 0;
}
.order-deliveryguy-rating {
	padding: 5px;
	text-align: center;
	font-size: 10px;
	font-weight: 500;
	color: #fff;
	border-radius: 2px;
}

.delivery-guy-status {
	padding: 10px;
	text-align: center;
	font-size: 16px;
	margin-top: 2rem;
	width: 50%;
	border-radius: 25px;
}

.delivery-guy-online {
	color: #fff;
	background-color: #18c775;
}

.delivery-guy-offline {
	color: #fff;
	background-color: red;
}
.delivery-guy-status-neutral {
	background-color: rgba(0, 0, 0, 0.25);
}

.schedule-order-btn {
	font-size: 14px;
	color: #fff;
	background-color: #282c3f;
	border-radius: 5px;
	display: flex;
	align-items: center;
}
.time-slots {
	padding: 8px 15px;
	font-size: 15px;
	font-weight: 500;
	text-transform: capitalize;
	border-radius: 4px;
	border: 1px solid #e0e0e0;
	margin-right: 5px;
}

.choose-order-time {
	color: #fff;
	font-size: 14px;
	background-color: green;
	padding: 5px;
	font-weight: 500;
	border-radius: 5px;
}

.confirm-slot {
	position: fixed;
	bottom: 0;
	padding: 15px 10px 30px 10px;
}

.available-time-slot-block {
	text-align: center;
	font-size: 15px;
	font-weight: 500;
}
.available-time-slot-block:first-child {
	margin-top: 20px;
}

.available-time-slot-block:last-child {
	margin-bottom: 130px;
}

.selected-slot {
	padding: 5px 10px;
	font-weight: 700;
	border-bottom: 3px solid #18c775;
}

.checkout-message-on-process {
	color: #9e9e9e;
}

.login-with-otp-input-container {
	justify-content: center;
}
.login-with-otp-input {
	width: 100% !important;
	height: 3.8rem;
	background-color: #f2f4f9;
	padding: 0 15px;
	border-radius: 4px;
	border: 0;
	margin-bottom: 10px;
	font-weight: 500;
}

.login-with-otp-input-container div {
	margin-right: 5px !important;
}
.login-with-otp-input-container div:last-child {
	margin-right: 0px !important;
}

.srv-validation-message {
	margin-top: -8px;
	margin-bottom: 8px;
}
.current-location-btn-overmap {
	position: absolute;
	right: 2%;
	top: 47%;
	padding: 10px;
	border-radius: 4px;
	text-align: center;
	line-height: 0rem;
	background-color: rgb(255 255 255);
	color: #ff7e01;
	font-weight: 500;
	box-shadow: rgb(0 0 0 / 15%) 0px 15px 25px, rgb(0 0 0 / 5%) 0px 5px 10px;
}
.cart-delivery-distance {
	font-size: 10px;
}
.order-confirmed-badge {
	color: #60b246 !important;
}
.slotNotAvalMsg:not(:first-child) {
	display: none;
}
.delivery-scheduleSlot-block {
	background-color: #222b45;
	border-radius: 8px;
}
.delivery-scheduleSlot-view-title {
	font-size: 13px;
	color: #fff;
}
.delivery-scheduleSlot-view-slot {
	font-size: 13px;
	color: #f44336;
	text-transform: capitalize;
}
.orderlist-scheduleSlot {
	text-transform: capitalize;
	font-size: 12px;
}

.paystack-absolute-logo {
	position: absolute;
	left: 30px;
	top: 16px;
	height: 3rem;
}

.useGpsBtn {
	background-color: #fff;
	padding: 5px 10px;
	min-width: fit-content;
	border-radius: 4px;
	color: #282c3f;
	text-align: center;
	font-weight: 500;
	box-shadow: 0px 2px 6px 2px rgb(0 0 0 / 15%);
}
.freeDeliveryMessageBlock {
	font-size: 11px;
}
